import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input, Radio, Select, SubmitButton } from "formik-antd";
import { Card, Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { RcFile } from "antd/es/upload";
import { generateRandomString } from "../../../../../utils/common";
import {
  ILocalBasicFormValues,
  IUserBasicFormValues,
} from "../../../../../types/user";
import UploadAvatar from "./UploadAvatar";

interface IUserBasicFormProps {
  img: string | null;
  email: string | null;
  name: string | null;
  surname: string | null;
  tag: any[];
  tags: any[];
  phone: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  streetNumber: string | null;
  deliveryPrice: number | null;
  typeAccount: string | number;
  isLoading: boolean;
  onUpload: (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => void;
  onAvatarRemove: (callback: () => void) => void;
  onSave: (
    values: IUserBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserBasicFormValues>) => void
  ) => void;
}

const UserBasicForm = (props: IUserBasicFormProps) => {
  const {
    img,
    email,
    name,
    surname,
    tag,
    tags,
    phone,
    typeAccount,
    isLoading,
    onUpload,
    onAvatarRemove,
    city,
    street,
    streetNumber,
    state,
    deliveryPrice,
    onSave,
  } = props;

  console.log(props, "props");
  const initialFormValues: ILocalBasicFormValues = {
    email: "",
    name: "",
    surname: "",
    tag: [],
    phone: "",
    typeAccount: 2,
    city: "",
    street: "",
    streetNumber: "",
    state: "",
    deliveryPrice: 0,
  };
  const [formData, setFormData] = useState<ILocalBasicFormValues>(
    initialFormValues
  );
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      email,
      name,
      surname,
      tag: tag?.map((t) => {
        return { label: t.name, value: t.id };
      }),
      phone,
      typeAccount,
      city,
      street,
      deliveryPrice,
      state,
      streetNumber,
    });
  }, [
    email,
    name,
    surname,
    tag,
    phone,
    img,
    typeAccount,
    city,
    street,
    deliveryPrice,
    state,
    streetNumber,
  ]);
  const formikRef = useRef<FormikValues>();
  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required("Pole wymagane"),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Dane restauracji">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={NewUserSchema}
          onSubmit={(
            values: ILocalBasicFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item
                label="Nazwa"
                name="name"
                required
                labelCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <Input name="name" />
              </Form.Item>
              <Form.Item
                label="Login"
                name="email"
                required
                rules={[{ required: true }]}
                labelCol={{ span: 24 }}
              >
                <Input name="email" />
              </Form.Item>
              <Form.Item
                label="Miasto"
                name="city"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="city" />
              </Form.Item>
              <Form.Item
                label="Ulica"
                name="street"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="street" />
              </Form.Item>
              <Form.Item
                label="Nr budynku"
                name="streetNumber"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="streetNumber" />
              </Form.Item>
              <Form.Item
                label="Województwo"
                name="state"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="state" />
              </Form.Item>
              <Form.Item
                label="Telefon"
                name="phone"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="phone" />
              </Form.Item>
              <Form.Item
                label="Cena dostawy"
                name="deliveryPrice"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="deliveryPrice" type="number" />
              </Form.Item>

              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default UserBasicForm;
