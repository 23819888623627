import React, {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import { Checkbox, Form, Input, Select } from "formik-antd";
import { Alert, Button, Col, Modal, Row } from "antd";
import { Formik, FormikValues } from "formik";
import { SettingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { generateRandomString } from "../../../utils/common";
import {
  INewLocalFormProps,
  INewLocalFormValues,
  INewUserFormProps,
  INewUserFormValues,
} from "../../../types/user";

const NewLocalForm: React.FC<INewLocalFormProps> = (
  props: PropsWithChildren<INewLocalFormProps>
): JSX.Element => {
  const { visible, onCancel, onCreate, isRequest, tags } = props;

  const formikRef = useRef<FormikValues>();

  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const initialFormValues: INewLocalFormValues = {
    email: "",
    name: "",
    surname: "",
    tag: [],
    phone: "",
    typeAccount: 2,
    city: "",
    street: "",
    streetNumber: "",
    state: "",
    deliveryPrice: 0,
    password: "",
  };

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required("Pole wymagane"),
    email: Yup.string().required("Pole wymagane"),
    password: Yup.string().required("Pole wymagane"),
    city: Yup.string().required("Pole wymagane"),
    street: Yup.string().required("Pole wymagane"),
    streetNumber: Yup.string().required("Pole wymagane"),
    state: Yup.string().required("Pole wymagane"),
    phone: Yup.string().required("Pole wymagane"),
    deliveryPrice: Yup.string().required("Pole wymagane"),
  });

  return (
    <Modal
      visible={visible}
      title="Nowa restauracja"
      okText="Dodaj"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={isRequest}
      onOk={() => {
        if (formikRef.current) {
          formikRef.current.setFieldTouched("tag");
          formikRef.current.handleSubmit();
        }
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={initialFormValues}
        validationSchema={NewUserSchema}
        validateOnBlur
        onSubmit={(values: INewLocalFormValues, { resetForm, setErrors }) => {
          onCreate(values, resetForm, setErrors);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Form.Item
              label="Nazwa"
              name="name"
              required
              labelCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Input name="name" />
            </Form.Item>
            <Form.Item
              label="Login"
              name="email"
              required
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
            >
              <Input name="email" />
            </Form.Item>
            <Form.Item
              label="Hasło"
              name="password"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Wprowadź hasło!" }]}
              required
            >
              <Row>
                <Col span={18}>
                  <Input name="password" style={{ flex: 1 }} />
                </Col>
                <Col span={6}>
                  <Button
                    onClick={generatePassword}
                    icon={<SettingOutlined />}
                    style={{ marginLeft: 8 }}
                    block
                  >
                    Generuj
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label="Miasto"
              name="city"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="city" />
            </Form.Item>
            <Form.Item
              label="Ulica"
              name="street"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="street" />
            </Form.Item>
            <Form.Item
              label="Nr budynku"
              name="streetNumber"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="streetNumber" />
            </Form.Item>
            <Form.Item
              label="Województwo"
              name="state"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="state" />
            </Form.Item>
            <Form.Item
              label="Telefon"
              name="phone"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="phone" />
            </Form.Item>
            <Form.Item
              label="Cena dostawy"
              name="deliveryPrice"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="deliveryPrice" type="number" />
            </Form.Item>
          </Form>
        )}
      />
    </Modal>
  );
};

export default NewLocalForm;
