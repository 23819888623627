import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";

class LocalsService {
  getAvailableOrdersList = async (
    begin: number,
    end: number,
    group?: number[]
  ): Promise<AxiosResponse> => {
    const response = await api.post(`orders/list`, {
      begin,
      end,
      group,
    });
    return response;
  };

  getOngoingDeliveries = async (
    begin: number,
    end: number,
    group?: number[]
  ): Promise<AxiosResponse> => {
    const response = await api.post(`deliveries/list`, {
      begin,
      end,
      group,
    });
    return response;
  };

  getSummary = async (
    begin: number,
    end: number,
    groups?: string[]
  ): Promise<AxiosResponse> => {
    const response = await api.post(`summary`, {
      begin,
      end,
      groups,
    });
    return response;
  };

  getRestaurant = async (): Promise<AxiosResponse> => {
    const response = await api.get(`restaurant`);
    return response;
  };

  getSummaryPdf = async (
    begin: number,
    end: number,
    groups?: string[]
  ): Promise<AxiosResponse> => {
    const response = await api.post(
      `summary/pdf`,
      {
        begin,
        end,
        groups,
      },
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    const newBlob = new Blob([response.data], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return response.data;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement("a");
    link.href = data;
    link.download = "report.pdf";
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    return response;
  };

  getLocal = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`locals/${id}`);
    return response;
  };

  // TODO: dodać typ usera (form)
  createOrder = async (order: any): Promise<AxiosResponse> => {
    const response = await api.post(`orders`, order);
    return response;
  };

  editDelay = async (
    id: any,
    take_away_time_delay: number | null
  ): Promise<AxiosResponse> => {
    const response = await api.post(`orders/${id}/delay`, {
      take_away_time_delay,
    });
    return response;
  };

  editLocal = async (
    id: number | string,
    local: any
  ): Promise<AxiosResponse> => {
    const response = await api.put(`locals/${id}`, local);
    return response;
  };

  deleteLocal = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`locals/${id}`);
    return response;
  };

  softDelete = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`locals/${id}`);
    return response;
  };
}

export default new LocalsService();
