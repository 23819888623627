import React, { PropsWithChildren, useState } from "react";
import { connect } from "react-redux";
import { Card, Col, Layout, Row } from "antd";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo_white.png";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { signUp } from "../../../actions/auth";
import { AppState } from "../../../reducers";
import { MetaTitle } from "../../Shared/MetaTitle";
import AuthService from "../../../services/auth-service";

interface IForgotPasswordProps {
  isRequest: boolean;
  isLogged: boolean;

  signUpAction: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;
}

interface ILocationStateType {
  from: { pathname: string };
}

const Login: React.FC<PropsWithChildren<IForgotPasswordProps>> = (
  props: PropsWithChildren<IForgotPasswordProps>
): JSX.Element => {
  const { Content } = Layout;
  const location = useLocation<ILocationStateType>();
  const history = useHistory();

  const { signUpAction, isRequest, isLogged } = props;

  const [isSent, setIsSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = (
    username: string,
    setStatus: (status: string) => void
  ) => {
    setIsLoading(true);
    AuthService.forgotPassword(username)
      .then(() => {
        setIsSent(true);
        setStatus(
          "Na podany adres email przesłano link do zmiany hasła. Postępuj zgodnie z instrukcją"
        );
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response?.status === 404) {
          setStatus("Brak adresu e-mail w systemie");
        }
        if (error.response?.status === 400) {
          setStatus("Podaj adres email");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLogged) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Layout>
      <MetaTitle title="Zapomniałem hasła" displayBadge={false} />
      <Content className="auth-login">
        <Row justify="center" gutter={16}>
          <Col span={24} className="auth-login__logo-container">
            <img src={logo} className="auth-login__logo" alt="logo" />
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          className="auth-login__container"
          gutter={[16, 16]}
        >
          <Col
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 2, span: 24 }}
          >
            <Card
              className="auth-login__card"
              bordered
              title="Zapomniałem hasła"
            >
              <ForgotPasswordForm
                isSent={isSent}
                handleSubmit={handleSubmit}
                isRequest={isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Row
          justify="center"
          className="auth-login__copyrights copyrights"
          gutter={16}
        >
          <Col span={24}>
            <p className="copyrights__text">© 2023 ABS DRIVE v0.0.2 29.09</p>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isRequest: state.auth.isRequest,
    isLogged: state.auth.isLogged,
  };
};

const mapDispatchToProps = {
  signUpAction: signUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
