import { AnyAction } from "redux";

import {
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS,
  BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP,
  BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_FAILED,
  BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS,
  CREATE_REQUEST,
  CREATE_REQUEST_FAILED,
  CREATE_REQUEST_SUCCESS,
  DELETE_USER_GROUP_MEMBERSHIP,
  EDIT_USER_REQUEST,
  EDIT_USER_REQUEST_FAILED,
  EDIT_USER_REQUEST_SUCCESS,
  FETCH_FAILED,
  FETCH_REQUEST,
  FETCH_TAGS,
  FETCH_TAGS_FAILED,
  FETCH_TAGS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_REQUEST_FAILED,
  FETCH_USER_REQUEST_SUCCESS,
  FETCH_USER_TICKET_GROUP_MEMBERSHIP,
  FETCH_USER_TICKET_GROUP_MEMBERSHIP_FAILED,
  FETCH_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS,
  REMOVE_REQUEST,
  REMOVE_REQUEST_FAILED,
  REMOVE_REQUEST_SUCCESS,
  SET_USER,
  SET_USERS,
} from "../../actions/action-types";

export interface UsersState {
  users: any[];
  user: any;
  tags: any[];
  groupsMembership: any[];
  isRequest: boolean;
  isFetchUserRequest: boolean;
  isEditRequest: boolean;
  isCreateRequest: boolean;
  isGroupsMembershipRequest: boolean;
  isBulkAssignUserTicketGroupMembership: boolean;
  isRemoveRequest: boolean;
  createUserError: any;
}

const initialState: UsersState = {
  users: [],
  user: {},
  tags: [],
  groupsMembership: [],
  isRequest: false,
  isEditRequest: false,
  isCreateRequest: false,
  createUserError: null,
  isRemoveRequest: false,
  isFetchUserRequest: false,
  isGroupsMembershipRequest: false,
  isBulkAssignUserTicketGroupMembership: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_REQUEST: {
      return {
        ...state,
        isRequest: true,
      };
    }
    case FETCH_TAGS: {
      return {
        ...state,
        isTagsRequest: true,
      };
    }
    case FETCH_TAGS_SUCCESS: {
      return {
        ...state,
        isTagsRequest: false,
        tags: payload,
      };
    }
    case FETCH_TAGS_FAILED: {
      return {
        ...state,
        isTagsRequest: false,
      };
    }

    case SET_USERS: {
      return {
        ...state,
        users: payload,
        isRequest: false,
      };
    }
    case FETCH_FAILED: {
      return {
        ...state,
        isRequest: false,
      };
    }
    case CREATE_REQUEST: {
      return {
        ...state,
        isCreateRequest: true,
      };
    }
    case CREATE_REQUEST_SUCCESS: {
      return {
        ...state,
        users: [...state.users],
        isCreateRequest: false,
      };
    }
    case CREATE_REQUEST_FAILED: {
      return {
        ...state,
        isCreateRequest: false,
        createUserError: payload,
      };
    }
    case REMOVE_REQUEST: {
      return {
        ...state,
        isRemoveRequest: true,
      };
    }
    case REMOVE_REQUEST_SUCCESS: {
      const indexOf = state.users.findIndex((user: any) => user.id === payload);

      return {
        ...state,
        users:
          indexOf >= 0
            ? [
                ...state.users.slice(0, indexOf),
                ...state.users.slice(indexOf + 1),
              ]
            : [...state.users],
        isRemoveRequest: false,
      };
    }
    case REMOVE_REQUEST_FAILED: {
      return {
        ...state,
        isRemoveRequest: false,
      };
    }
    case FETCH_USER_REQUEST: {
      return {
        ...state,
        isFetchUserRequest: true,
      };
    }
    case FETCH_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetchUserRequest: false,
        user: payload,
      };
    }
    case FETCH_USER_REQUEST_FAILED: {
      return {
        ...state,
        isFetchUserRequest: false,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    case EDIT_USER_REQUEST: {
      return {
        ...state,
        isEditRequest: true,
      };
    }
    case EDIT_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        isEditRequest: false,
      };
    }
    case EDIT_USER_REQUEST_FAILED: {
      return {
        ...state,
        isEditRequest: false,
      };
    }
    case FETCH_USER_TICKET_GROUP_MEMBERSHIP: {
      return {
        ...state,
        isGroupsMembershipRequest: true,
      };
    }
    case FETCH_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        isGroupsMembershipRequest: false,
        groupsMembership: payload,
      };
    }
    case FETCH_USER_TICKET_GROUP_MEMBERSHIP_FAILED: {
      return {
        ...state,
        isGroupsMembershipRequest: false,
      };
    }

    case BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP: {
      return {
        ...state,
        isBulkAssignUserTicketGroupMembership: true,
      };
    }
    case BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        groupsMembership: [
          ...state.groupsMembership,
          ...payload.map((res: any) => res.group),
        ],
        isBulkAssignUserTicketGroupMembership: false,
      };
    }
    case BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_FAILED: {
      return {
        ...state,
        isBulkAssignUserTicketGroupMembership: false,
      };
    }

    case DELETE_USER_GROUP_MEMBERSHIP: {
      const indexOf = state.groupsMembership.findIndex(
        (group: any) => group.id === payload
      );
      return {
        ...state,
        groupsMembership:
          indexOf >= 0
            ? [
                ...state.groupsMembership.slice(0, indexOf),
                ...state.groupsMembership.slice(indexOf + 1),
              ]
            : [...state.groupsMembership],
      };
    }

    default:
      return state;
  }
};
