import React, { PropsWithChildren } from "react";
import { Checkbox, Form, Input } from "formik-antd";
import { Alert, Button, Row, Space } from "antd";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

interface ILoginFormProps {
  isRequest: boolean;
  handleSubmit: (
    username: string,
    password: string,
    remember: boolean,
    setStatus: (status: string) => void
  ) => void;
}
interface IFormValues {
  email: string;
  password: string;
  remember: boolean;
}

const LoginForm: React.FC<ILoginFormProps> = (
  props: PropsWithChildren<ILoginFormProps>
): JSX.Element => {
  const onSubmit = (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    const { email, password, remember } = values;
    actions.setStatus(null);
    props.handleSubmit(email, password, remember, actions.setStatus);
  };

  const initialFormValues: IFormValues = {
    email: "",
    password: "",
    remember: false,
  };
  const UserCredentialSchema = Yup.object().shape({
    email: Yup.string()
      .email("Niepoprawny adres email")
      .required("Pole wymagane"),
    password: Yup.string().required("Pole wymagane"),
  });

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
      validateOnChange
      validationSchema={UserCredentialSchema}
      render={({ status }) => (
        <Form>
          {status && (
            <Alert message={status} type="error" style={{ marginBottom: 8 }} />
          )}
          <Form.Item
            label="E-mail"
            name="email"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Wprowadź email!" }]}
          >
            <Input name="email" />
          </Form.Item>

          <Form.Item
            label="Hasło"
            name="password"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Wprowadź hasło!" }]}
          >
            <Input.Password name="password" />
          </Form.Item>

          <Row justify="space-around" wrap>
            <Button
              type="primary"
              htmlType="submit"
              loading={props.isRequest}
              className="auth-login__submit"
            >
              Zaloguj
            </Button>
          </Row>
        </Form>
      )}
    />
  );
};

export default LoginForm;
