/* eslint-disable react/destructuring-assignment */
import React, { PropsWithChildren } from "react";
import { Button, Space, Table, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { FieldTimeOutlined } from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment/moment";
import { formatSeconds } from "../../../utils/common";
import Can from "../../Shared/Can";

const TicketList = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, isRequest, isMobile, isAdmin, handleEditTime } = props;
  let columns = [
    {
      title: "Wolne dostawy",
      dataIndex: "create",
      key: "create",
      hidden: !isMobile,
      width: "80%",
      render: (name: string, group: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Link
            to={`/group/${group.id}`}
            className="jb-link jb-link--secendary"
          >
            {name}
          </Link>

          <Tooltip title={formatSeconds(parseInt(group.seconds, 10), true)}>
            <Text style={{ marginTop: 16 }}>
              <small>
                Wypracowane: {formatSeconds(parseInt(group.seconds, 10))}
              </small>
            </Text>
          </Tooltip>

          <Text>
            <small>Limit: {group.limit}h</small>
          </Text>
        </div>
      ),
    },
    {
      title: "Data utworzenia",
      dataIndex: "create",
      key: "create",
      hidden: isMobile,
      render: (date: string, group: any) => (
        <span> {moment(date).format("DD.MM.YYYY HH:mm")}</span>
      ),
    },

    {
      title: "Restauracja",
      dataIndex: "local",
      key: "local",
      sorter: (a: any, b: any) => {
        if (!a.local?.name || !b.local?.name) return -1;
        return a.local?.name.localeCompare(b.local?.name);
      },
      hidden: !isAdmin,
      // eslint-disable-next-line react/destructuring-assignment
      render: (local: any, group: any) => <span> {local?.name}</span>,
    },
    {
      title: "Kierowca",
      dataIndex: "driver",
      key: "driver",
      sorter: false,
      // eslint-disable-next-line react/destructuring-assignment
      render: (driver: any, group: any) => (
        <>
          <span>
            {driver?.carrier?.name}
            <p>tel: {driver?.carrier?.phone}</p>
          </span>
        </>
      ),
    },
    {
      title: "Adres dostawy",
      dataIndex: "orders",
      hidden: isMobile,
      key: "orders",
      // eslint-disable-next-line react/destructuring-assignment
      render: (orders: any, group: any) => (
        <span>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {orders?.[0]?.delivery?.address} {orders?.[0]?.delivery?.city}
        </span>
      ),
    },
    {
      title: "Kwota dostawy",
      dataIndex: "sum",
      hidden: isMobile,
      key: "sum",
      // eslint-disable-next-line react/destructuring-assignment
      render: (sum: any, group: any) => <span>{sum} zł</span>,
    },

    {
      title: "Data odbioru",
      dataIndex: "orders",
      hidden: isMobile,
      key: "orders",
      // eslint-disable-next-line react/destructuring-assignment
      render: (orders: any, group: any) => (
        // eslint-disable-next-line react/destructuring-assignment
        <Space>
          {" "}
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {orders[0]?.take_away_time_delay ? (
            <span style={{ color: "red" }}>
              {moment(orders[0]?.take_away_time_delay).format(
                "DD.MM.YYYY HH:mm"
              )}
            </span>
          ) : (
            moment(orders[0]?.take_away_time).format("DD.MM.YYYY HH:mm")
          )}
          <Can type="is_admin">
            <Tooltip title="Edytuj" mouseEnterDelay={1}>
              <Button
                icon={<FieldTimeOutlined />}
                onClick={() =>
                  handleEditTime(orders[0].id, orders[0]?.take_away_time)
                }
              />
            </Tooltip>
          </Can>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "driver",
      hidden: isMobile,
      key: "driver",
      // eslint-disable-next-line react/destructuring-assignment
      render: (driver: any, group: any) => {
        switch (driver.status) {
          case 1:
            return <Tag color="#FFBF00">Przyjęte</Tag>;
          case 5:
            return <Tag color="#7CB9E8">W trakcie</Tag>;
          case 10:
            return <Tag color="#FF0000">Nieudana dostawa</Tag>;
          case 12:
            return <Tag color="#ffa500">Rezygnacja dostawa</Tag>;
          case 15:
            return <Tag color="#84DE02">Dostarczone</Tag>;
          default:
            return <Tag color="#ddd">Oczekuje</Tag>;
        }
      },
    },
  ];

  columns = columns.filter((row) => !row.hidden);
  return (
    <Table
      style={{ width: "100%" }}
      locale={{ emptyText: "Brak danych" }}
      size="middle"
      dataSource={data}
      columns={columns}
      pagination={{ position: ["bottomRight"], pageSize: 20 }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default TicketList;
