import React, { PropsWithChildren } from "react";
import { Button, Space, Table, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  FieldTimeOutlined,
  LockOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { formatMoney, formatSeconds } from "../../../utils/common";
import { UserFilledIcon } from "../UserFillled";
import { UserOutlinedIcon } from "../UserOutlined";
import TrimmedTitle from "../TrimmedTitle";
import { UserIcon } from "../UserIcon";

const List = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, isRequest, users, total } = props;
  const columns = [
    {
      title: "Data utworzenia",
      dataIndex: "create",
      key: "create",
      render: (date: string, group: any) => (
        <span> {moment(date).format("DD.MM.YYYY HH:mm")}</span>
      ),
    },
    {
      title: "Restauracja",
      dataIndex: "local",
      key: "local",
      sorter: (a: any, b: any) => {
        if (!a.local?.name || !b.local?.name) return -1;
        return a.local?.name.localeCompare(b.local?.name);
      },
      // eslint-disable-next-line react/destructuring-assignment
      render: (local: any, group: any) => <span> {local?.name}</span>,
    },
    {
      title: "Adres dostawy",
      dataIndex: "orders",
      key: "orders",
      // eslint-disable-next-line react/destructuring-assignment
      render: (orders: any, group: any) => (
        <span>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {orders?.[0]?.delivery?.address} {orders?.[0]?.delivery?.city}
        </span>
      ),
    },
    {
      title: "Kwota dostawy",
      dataIndex: "sum",
      key: "sum",
      // eslint-disable-next-line react/destructuring-assignment
      render: (sum: any, group: any) => <span>{sum} zł</span>,
    },
    {
      title: "Pobrano z karty",
      dataIndex: "driverPayments",
      key: "driverPayments",
      // eslint-disable-next-line react/destructuring-assignment
      render: (driverPayments: any, group: any) => {
        if (!driverPayments) return "-";
        return (
          driverPayments
            .filter((payment: any) => payment.payment_type === 2)
            .map((payment: any) => {
              return <span>{payment.amount} zł</span>;
            }) || "-"
        );
      },
    },
    {
      title: "Pobrano gotówki",
      dataIndex: "driverPayments",
      key: "driverPayments",
      // eslint-disable-next-line react/destructuring-assignment
      render: (driverPayments: any, group: any) => {
        if (!driverPayments) return "-";
        return (
          driverPayments
            .filter((payment: any) => payment.payment_type === 1)
            .map((payment: any) => {
              return <span>{payment.amount} zł</span>;
            }) || "-"
        );
      },
    },
    {
      title: "Koszt dostawy",
      dataIndex: "driver",
      key: "driver",
      // eslint-disable-next-line react/destructuring-assignment
      render: (driver: any, group: any) => {
        // eslint-disable-next-line react/destructuring-assignment
        return <span>{driver.carrier_delivery_price} zł</span>;
      },
    },
    {
      title: "Data odbioru",
      dataIndex: "orders",
      key: "orders",
      // eslint-disable-next-line react/destructuring-assignment
      render: (orders: any, group: any) => (
        // eslint-disable-next-line react/destructuring-assignment
        <span>
          {" "}
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {moment(orders[0]?.take_away_time).format("DD.MM.YYYY HH:mm")}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "driver",
      key: "driver",
      // eslint-disable-next-line react/destructuring-assignment
      render: (driver: any, group: any) => {
        switch (driver.status) {
          case 1:
            return <Tag color="#FFBF00">Przyjęte</Tag>;
          case 5:
            return <Tag color="#7CB9E8">W trakcie</Tag>;
          case 10:
            return <Tag color="#FF0000">Nieudana dostawa</Tag>;
          case 12:
            return <Tag color="#ffa500">Rezygnacja dostawa</Tag>;
          case 15:
            return <Tag color="#84DE02">Dostarczone</Tag>;
          default:
            return <Tag color="#ddd">Oczekuje</Tag>;
        }
      },
    },
  ];

  return (
    <Table
      className="single-month"
      style={{ width: "100%" }}
      scroll={{ x: true }}
      locale={{ emptyText: "Brak danych" }}
      size="small"
      dataSource={data}
      columns={columns}
      pagination={false}
      loading={isRequest}
      summary={() => (
        <>
          {total && (
            <>
              <Table.Summary.Row className="single-month__summary--total">
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3}>
                  <Text strong>Suma</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Text strong>{total.total_received?.toFixed(2)} zł</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <Text strong>{total.card?.toFixed(2)} zł</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Text strong>{total.cash?.toFixed(2)} zł</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Text strong>{total.delivery_price?.toFixed(2)} zł</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <Text strong>Saldo: {total.summary?.toFixed(2)} zł</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} />
              </Table.Summary.Row>
            </>
          )}
          {Object.values(users).map((userObj: any) => (
            <Table.Summary.Row className="single-month__summary--day">
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4} />
              <Table.Summary.Cell index={5}>
                <Text strong style={{ textTransform: "capitalize" }}>
                  {userObj.user.name} {userObj.user.surname}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <Tooltip
                  title={formatSeconds(parseInt(userObj.seconds, 10), true)}
                >
                  <Text strong>
                    {formatSeconds(parseInt(userObj.seconds, 10))}
                  </Text>
                </Tooltip>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} />
              <Table.Summary.Cell index={8} />
              <Table.Summary.Cell index={9} />
            </Table.Summary.Row>
          ))}
        </>
      )}
      rowKey="id"
    />
  );
};

export default List;
