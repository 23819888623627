import { combineReducers } from "redux";
import authReducer, { AuthState } from "./auth/index";
import usersReducer, { UsersState } from "./users";
import rolesReducer, { RolesState } from "./roles";
import ticketgroupsReducer, { TicketGroupsState } from "./taskgroups";
import globalReducer, { GlobalState } from "./global";

import homeReducer, { HomeState } from "./home";
import ticketReducer, { TicketsState } from "./tickets";
import worktimeReducer, { WorktimeState } from "./worktimes";
import notificationReducer, { NotificationsState } from "./notifications";
import chatReducer, { ChatState } from "./chat";
import localsReducer, { LocalsState } from "./locals";

export interface AppState {
  home: HomeState;
  auth: AuthState;
  users: UsersState;
  locals: LocalsState;
  roles: RolesState;
  ticketgroups: TicketGroupsState;
  global: GlobalState;
  ticket: TicketsState;
  worktime: WorktimeState;
  notifications: NotificationsState;
  chat: ChatState;
}

export default combineReducers({
  global: globalReducer,
  home: homeReducer,
  auth: authReducer,
  users: usersReducer,
  locals: localsReducer,
  roles: rolesReducer,
  ticketgroups: ticketgroupsReducer,
  ticket: ticketReducer,
  worktime: worktimeReducer,
  notifications: notificationReducer,
  chat: chatReducer,
});
