import React, {
  createRef,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import { Checkbox, DatePicker, Form, Input, Radio, Select } from "formik-antd";
import { Alert, Button, Col, Modal, Row, Space } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import { SettingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import locale from "antd/es/date-picker/locale/pl_PL";
import moment from "moment";
import { generateRandomString } from "../../../utils/common";
import { INewOrderFormProps, INewOrderFormValues } from "../../../types/user";

const NewOrderForm: React.FC<INewOrderFormProps> = (
  props: PropsWithChildren<INewOrderFormProps>
): JSX.Element => {
  const { visible, onCancel, onCreate, isRequest, address } = props;
  const [open, setOpen] = React.useState(false);
  const formikRef = useRef<FormikValues>();
  const datePickerRef = createRef<any>();

  const handleAddMinutes = (minutes: number, formProps: any) => {
    const now = moment();

    const updatedDate = now.add(minutes, "minutes");
    formProps.setFieldValue("takeAway", updatedDate);
    setOpen(false);
  };
  const footerRender = (formProps: any) => (
    <Space wrap style={{ padding: 10 }}>
      <Button
        onClick={() => handleAddMinutes(0, formProps)}
        style={{ minWidth: 60 }}
      >
        Teraz
      </Button>
      <Button
        onClick={() => handleAddMinutes(15, formProps)}
        style={{ minWidth: 60 }}
      >
        15 min
      </Button>
      <Button
        onClick={() => handleAddMinutes(30, formProps)}
        style={{ minWidth: 60 }}
      >
        30 min
      </Button>
      <Button
        onClick={() => handleAddMinutes(45, formProps)}
        style={{ minWidth: 60 }}
      >
        45 min
      </Button>
      <Button
        onClick={() => handleAddMinutes(60, formProps)}
        style={{ minWidth: 60 }}
      >
        1h
      </Button>
      <Button
        onClick={() => handleAddMinutes(75, formProps)}
        style={{ minWidth: 60 }}
      >
        1h 15 min
      </Button>
      <Button
        onClick={() => handleAddMinutes(90, formProps)}
        style={{ minWidth: 60 }}
      >
        1h 30 min
      </Button>
      <Button
        onClick={() => handleAddMinutes(105, formProps)}
        style={{ minWidth: 60 }}
      >
        1h 45 min
      </Button>
      <Button
        onClick={() => handleAddMinutes(120, formProps)}
        style={{ minWidth: 60 }}
      >
        2h
      </Button>
    </Space>
  );

  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const initialFormValues: INewOrderFormValues = {
    notes: "",
    takeAway: moment().unix() * 1000,
    phone: "",
    city: address?.city || "",
    street: "",
    streetNumber: "",
    state: address?.state || "",
    totalPrice: 0,
    payment: 1,
  };

  const NewUserSchema = Yup.object().shape({
    totalPrice: Yup.number().required("Pole wymagane"),
    takeAway: Yup.string().required("Pole wymagane"),
    city: Yup.string().required("Pole wymagane"),
    street: Yup.string().required("Pole wymagane"),
    streetNumber: Yup.string().required("Pole wymagane"),
    state: Yup.string().required("Pole wymagane"),
    payment: Yup.string().required("Pole wymagane"),
  });

  return (
    <Modal
      visible={visible}
      title="Nowa dostawa"
      okText="Dodaj"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={isRequest}
      onOk={() => {
        if (formikRef.current) {
          formikRef.current.setFieldTouched("tag");
          formikRef.current.handleSubmit();
        }
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={initialFormValues}
        validationSchema={NewUserSchema}
        validateOnBlur
        onSubmit={(values: INewOrderFormValues, { resetForm, setErrors }) => {
          onCreate(values, resetForm, setErrors);
        }}
        validateOnChange
        render={(formProps: FormikProps<any>) => (
          <Form>
            <Form.Item
              label="Ulica"
              name="street"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="street" />
            </Form.Item>
            <Form.Item
              label="Nr budynku"
              name="streetNumber"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="streetNumber" />
            </Form.Item>
            <Form.Item
              label="Miasto"
              name="city"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="city" />
            </Form.Item>
            <Form.Item
              label="Województwo"
              name="state"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="state" />
            </Form.Item>
            <Form.Item
              label="Telefon"
              name="phone"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="phone" />
            </Form.Item>
            <Form.Item
              label="Wartość zamówienia"
              name="totalPrice"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="totalPrice" type="number" />
            </Form.Item>
            <Form.Item
              label="Data odbioru przez kuriera"
              required
              name="takeAway"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Wprowadź datę odbioru!" }]}
            >
              <DatePicker
                getPopupContainer={(trigger) =>
                  trigger.parentElement?.parentElement as HTMLElement
                }
                open={open}
                style={{ minWidth: "100%" }}
                locale={locale}
                onOpenChange={(change) => setOpen(change)}
                onChange={(change) => {
                  formProps.setFieldValue("takeAway", change?.toDate());
                }}
                value={moment(formProps.values.takeAway)}
                renderExtraFooter={() => footerRender(formProps)}
                format="DD.MM.Y HH:mm"
                showTime={{ showSecond: false }}
                showNow={false}
                name="start"
              />
            </Form.Item>
            <Form.Item
              name="payment"
              label="Forma rozliczenia"
              labelCol={{ span: 24 }}
            >
              <Radio.Group name="payment" buttonStyle="solid">
                <Radio.Button value={1}>Gotówka</Radio.Button>
                <Radio.Button value={2}>Karta kredytowa</Radio.Button>
                <Radio.Button value={8}>Płatność online</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Uwagi" name="notes" labelCol={{ span: 24 }}>
              <Input.TextArea name="notes" />
            </Form.Item>
          </Form>
        )}
      />
    </Modal>
  );
};

export default NewOrderForm;
