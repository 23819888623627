import { AnyAction } from "redux";

import {
  CREATE_LOCAL_REQUEST,
  CREATE_LOCAL_REQUEST_FAILED,
  CREATE_LOCAL_REQUEST_SUCCESS,
  CREATE_REQUEST,
  CREATE_REQUEST_FAILED,
  CREATE_REQUEST_SUCCESS,
  EDIT_LOCAL_REQUEST,
  EDIT_LOCAL_REQUEST_FAILED,
  EDIT_LOCAL_REQUEST_SUCCESS,
  EDIT_USER_REQUEST,
  EDIT_USER_REQUEST_FAILED,
  EDIT_USER_REQUEST_SUCCESS,
  FETCH_FAILED,
  FETCH_LOCAL_REQUEST,
  FETCH_LOCAL_REQUEST_FAILED,
  FETCH_LOCAL_REQUEST_SUCCESS,
  FETCH_LOCALS_FAILED,
  FETCH_LOCALS_REQUEST,
  FETCH_REQUEST,
  FETCH_USER_REQUEST,
  FETCH_USER_REQUEST_FAILED,
  FETCH_USER_REQUEST_SUCCESS,
  REMOVE_LOCAL_REQUEST,
  REMOVE_LOCAL_REQUEST_FAILED,
  REMOVE_LOCAL_REQUEST_SUCCESS,
  REMOVE_REQUEST,
  REMOVE_REQUEST_FAILED,
  REMOVE_REQUEST_SUCCESS,
  SET_LOCAL,
  SET_LOCALS,
  SET_USER,
  SET_USERS,
} from "../../actions/action-types";

export interface LocalsState {
  locals: any[];
  local: any;
  tags: any[];
  groupsMembership: any[];
  isRequest: boolean;
  isFetchLocalRequest: boolean;
  isEditRequest: boolean;
  isCreateRequest: boolean;
  isGroupsMembershipRequest: boolean;
  isBulkAssignLocalTicketGroupMembership: boolean;
  isRemoveRequest: boolean;
  createLocalError: any;
}

const initialState: LocalsState = {
  locals: [],
  local: {},
  tags: [],
  groupsMembership: [],
  isRequest: false,
  isEditRequest: false,
  isCreateRequest: false,
  createLocalError: null,
  isRemoveRequest: false,
  isFetchLocalRequest: false,
  isGroupsMembershipRequest: false,
  isBulkAssignLocalTicketGroupMembership: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LOCALS_REQUEST: {
      return {
        ...state,
        isRequest: true,
      };
    }

    case SET_LOCALS: {
      return {
        ...state,
        locals: payload,
        isRequest: false,
      };
    }
    case FETCH_LOCALS_FAILED: {
      return {
        ...state,
        isRequest: false,
      };
    }
    case CREATE_LOCAL_REQUEST: {
      return {
        ...state,
        isCreateRequest: true,
      };
    }
    case CREATE_LOCAL_REQUEST_SUCCESS: {
      return {
        ...state,
        locals: [...state.locals],
        isCreateRequest: false,
      };
    }
    case CREATE_LOCAL_REQUEST_FAILED: {
      return {
        ...state,
        isCreateRequest: false,
        createLocalError: payload,
      };
    }
    case REMOVE_LOCAL_REQUEST: {
      return {
        ...state,
        isRemoveRequest: true,
      };
    }
    case REMOVE_LOCAL_REQUEST_SUCCESS: {
      const indexOf = state.locals.findIndex(
        (user: any) => user.id === payload
      );

      return {
        ...state,
        locals:
          indexOf >= 0
            ? [
                ...state.locals.slice(0, indexOf),
                ...state.locals.slice(indexOf + 1),
              ]
            : [...state.locals],
        isRemoveRequest: false,
      };
    }
    case REMOVE_LOCAL_REQUEST_FAILED: {
      return {
        ...state,
        isRemoveRequest: false,
      };
    }
    case FETCH_LOCAL_REQUEST: {
      return {
        ...state,
        isFetchLocalRequest: true,
      };
    }
    case FETCH_LOCAL_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetchLocalRequest: false,
        local: payload,
      };
    }
    case FETCH_LOCAL_REQUEST_FAILED: {
      return {
        ...state,
        isFetchLocalRequest: false,
      };
    }
    case SET_LOCAL: {
      return {
        ...state,
        local: payload,
      };
    }
    case EDIT_LOCAL_REQUEST: {
      return {
        ...state,
        isEditRequest: true,
      };
    }
    case EDIT_LOCAL_REQUEST_SUCCESS: {
      return {
        ...state,
        isEditRequest: false,
      };
    }
    case EDIT_LOCAL_REQUEST_FAILED: {
      return {
        ...state,
        isEditRequest: false,
      };
    }

    default:
      return state;
  }
};
