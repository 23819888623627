import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Grid,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import {
  ClockCircleOutlined,
  EditOutlined,
  FieldTimeOutlined,
  MessageOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import classnames from "classnames";

import Text from "antd/es/typography/Text";
import moment from "moment";
import {
  makeAvatarName,
  renderLastActivity,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";
import RemoveRecord from "../../Shared/RemoveRecord";
import useIsMobile from "../../../hooks/useIsMobile";

const { TabPane } = Tabs;

interface IUsersPageProps {
  users: IUserList[];
  isRequest: boolean;
  isDeleteRequest: boolean;
  onDelete: (user: any, callback: () => void) => void;
}

interface IUserList {
  id: string;
  email: string;
  username: string;
  name: string;
  tag: any;
  surname: string;
  description: string;
  invitationStatus: number;
  phone: string;
  avatar: string;
  img: string;
  lastActivity: {
    id: number;
    date: number;
  };
  typeAccount: number;
  role: IRole[];
  isOnline: boolean;
  isIdle: boolean;
}
interface IRole {
  id: number;
  name: string;
}

const UsersList = (props: PropsWithChildren<IUsersPageProps>): JSX.Element => {
  const { users, isRequest, onDelete } = props;

  const isMobile = useIsMobile();

  interface IUserAvatar {
    img: string;
    avatarColor: string | null;
    avatarBackground: string | null;
    name: string;
    surname: string;
    email: string;
  }

  const UserAvatar = (user: IUserAvatar) => {
    const { img, avatarColor, avatarBackground, name, surname } = user;
    return (
      <Avatar
        size={40}
        src={img || null}
        className="user-app__avatar user-app__avatar--user-list"
        style={{
          color: avatarColor || "white",
          backgroundColor: avatarBackground || stringToColour(name), // $complementary-blue
          borderColor: "#fefefe",
          borderWidth: 1,
          borderStyle: "solid",
          minWidth: 40,
        }}
      >
        {makeAvatarName(false, name, "")}
      </Avatar>
    );
  };

  let columns = [
    {
      title: "",
      dataIndex: "img",
      key: "img",
      hidden: isMobile,
      render: (image: string, user: IUserList) => {
        const { img, name, surname, email } = user;
        return (
          <UserAvatar
            img={img}
            avatarColor={null}
            avatarBackground={null}
            name={name}
            surname={surname}
            email={email}
          />
        );
      },
      width: 60,
    },
    {
      title: "Login kierowcy",
      dataIndex: "name",
      className: "table__column--name",
      key: "name",
      minWidth: 130,
      hidden: isMobile,
      render: (name: string, user: IUserList) => {
        if (!name) return "";

        return `${name}`;
      },
      ellipsis: true,
      sorter: (a: IUserList, b: IUserList) => {
        if (!a.name || !b.name) return -1;
        const aName = `${a.name} `;
        const bName = `${b.name} `;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Imię Nazwisko",
      dataIndex: "name",
      className: "table__column--user-table-info user-table-info",
      key: "nameMobile",
      hidden: !isMobile,
      render: (name: string, user: IUserList) => {
        const lastActive = user?.lastActivity?.date;
        return (
          <div className="user-table-info__container">
            <div className="user-table-info__header">
              <UserAvatar
                img={user.img}
                avatarColor={null}
                avatarBackground={null}
                name={name}
                surname={user.surname}
                email={user.email}
              />
              <div style={{ width: "100%" }}>
                <div className="user-table-info__name-row">
                  <p className="user-table-info__name">{name}</p>
                  <Space size="small" align="end">
                    <Tooltip title="Edytuj" mouseEnterDelay={1}>
                      <Link to={`/users/edit/${user.id}`}>
                        <Button icon={<EditOutlined />} size="small" />
                      </Link>
                    </Tooltip>
                    <RemoveRecord
                      title="Czy napewno chcesz usunąć kierowce"
                      onConfirm={(callbackHidePopover: () => void) =>
                        onDelete(user, callbackHidePopover)
                      }
                      okText="Usuń"
                      cancelText="Anuluj"
                      tooltipText="Usuń"
                      size="small"
                    />
                  </Space>
                </div>

                <p className="user-table-info__email">{user.name}</p>
              </div>
            </div>
          </div>
        );
      },
      sorter: (a: IUserList, b: IUserList) => {
        if (!a.name || !b.name) return -1;
        const aName = `${a.name}`;
        const bName = `${b.name}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },

    {
      title: "Akcje",
      key: "action",
      className: "table__column--actions",
      width: 100,
      hidden: isMobile,
      fixed: "right" as const,
      render: (text: string, record: IUserList) => (
        <Space size="middle">
          <Tooltip title="Edytuj" mouseEnterDelay={1}>
            <Link to={`/users/edit/${record.id}`}>
              <Button icon={<EditOutlined />} />
            </Link>
          </Tooltip>
          <RemoveRecord
            title="Czy napewno chcesz usunąć kierowce?"
            onConfirm={(callbackHidePopover: () => void) =>
              onDelete(record, callbackHidePopover)
            }
            okText="Usuń"
            cancelText="Anuluj"
            tooltipText="Usuń"
          />
        </Space>
      ),
    },
  ];

  columns = columns.filter((row: any) => row.hidden === false);

  return (
    <Table
      size={isMobile ? "small" : "middle"}
      locale={{ emptyText: "Brak danych" }}
      dataSource={users}
      className="users-page__table table"
      scroll={{ x: isMobile ? 0 : 1000 }}
      columns={columns}
      pagination={{ position: ["bottomRight"] }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default UsersList;
