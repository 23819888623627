import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";

class UsersService {
  getUsers = async (): Promise<AxiosResponse> => {
    const response = await api.get("drivers");
    return response;
  };

  getUsersAdmin = async (): Promise<AxiosResponse> => {
    const response = await api.get("drivers");
    return response;
  };

  getUser = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`drivers/${id}`);
    return response;
  };

  getUserGroupsMembership = async (
    id: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`drivers/${id}/ticket-groups-member`);
    return response;
  };

  // TODO: dodać typ usera (form)
  createUser = async (user: any): Promise<AxiosResponse> => {
    const response = await api.post(`drivers`, user);
    return response;
  };

  editUser = async (id: number | string, user: any): Promise<AxiosResponse> => {
    const response = await api.put(`drivers/${id}`, user);
    return response;
  };

  editOwnUser = async (user: any): Promise<AxiosResponse> => {
    const response = await api.patch(`drivers/me/edit`, user);
    return response;
  };

  deleteUser = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`drivers/${id}`);
    return response;
  };

  softDelete = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`drivers/${id}`);
    return response;
  };

  uploadAvatar = async (
    id: string | number,
    file: string | RcFile | Blob
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.set("avatar", file);
    const response = await api.post(`drivers/${id}/uploadAvatar`, formData);
    return response;
  };

  uploadOwnAvatar = async (
    file: string | RcFile | Blob
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.set("avatar", file);
    const response = await api.post(`drivers/me/edit/uploadAvatar`, formData);
    return response;
  };

  getTags = async (): Promise<AxiosResponse> => {
    const response = await api.get("drivers/tags");
    return response;
  };

  updateMeta = async (meta: any) => {
    const response = await api.post(`drivers/meta`, { meta });
    return response;
  };
}

export default new UsersService();
