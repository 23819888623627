import React, {
  createRef,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import { Checkbox, DatePicker, Form, Input, Radio, Select } from "formik-antd";
import { Alert, Button, Col, Modal, Row, Space } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import { SettingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import locale from "antd/es/date-picker/locale/pl_PL";
import moment from "moment";
import { generateRandomString } from "../../../utils/common";
import { INewOrderFormProps, INewOrderFormValues } from "../../../types/user";

const EditTime: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const { visible, onCancel, onEdit, isRequest, address, order } = props;
  const [open, setOpen] = React.useState(false);
  const formikRef = useRef<FormikValues>();
  const datePickerRef = createRef<any>();

  const handleAddMinutes = (minutes: number) => {
    const time = moment(order.take_away_time);
    const updatedDate = minutes ? time.add(minutes, "minutes") : null;
    onEdit(updatedDate);
  };
  const footerRender = () => (
    <Space wrap style={{ padding: 10 }}>
      <Button onClick={() => handleAddMinutes(0)} style={{ minWidth: 60 }}>
        Brak
      </Button>
      <Button onClick={() => handleAddMinutes(15)} style={{ minWidth: 60 }}>
        15 min
      </Button>
      <Button onClick={() => handleAddMinutes(30)} style={{ minWidth: 60 }}>
        30 min
      </Button>
      <Button onClick={() => handleAddMinutes(45)} style={{ minWidth: 60 }}>
        45 min
      </Button>
      <Button onClick={() => handleAddMinutes(60)} style={{ minWidth: 60 }}>
        1h
      </Button>
      <Button onClick={() => handleAddMinutes(75)} style={{ minWidth: 60 }}>
        1h 15 min
      </Button>
      <Button onClick={() => handleAddMinutes(90)} style={{ minWidth: 60 }}>
        1h 30 min
      </Button>
      <Button onClick={() => handleAddMinutes(105)} style={{ minWidth: 60 }}>
        1h 45 min
      </Button>
      <Button onClick={() => handleAddMinutes(120)} style={{ minWidth: 60 }}>
        2h
      </Button>
    </Space>
  );

  return (
    <Modal
      visible={visible}
      title="Opóźnienie odbioru"
      cancelText="Anuluj"
      footer={null}
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={isRequest}
    >
      {footerRender()}
    </Modal>
  );
};

export default EditTime;
