import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input, Radio, Select, SubmitButton } from "formik-antd";
import { Card, Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { RcFile } from "antd/es/upload";
import { generateRandomString } from "../../../../../utils/common";
import {
  ILocalBasicFormValues,
  IPosLocalBasicFormValues,
  IUserBasicFormValues,
} from "../../../../../types/user";
import UploadAvatar from "./UploadAvatar";

interface IPosLocalBasicFormProps {
  deliveryPrice: number | null;

  isLoading: boolean;
  onUpload: (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => void;
  onAvatarRemove: (callback: () => void) => void;
  onSave: (
    values: IPosLocalBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IPosLocalBasicFormValues>) => void
  ) => void;
}

const PosLocalBasicForm = (props: IPosLocalBasicFormProps) => {
  const {
    isLoading,
    onUpload,
    onAvatarRemove,

    deliveryPrice,
    onSave,
  } = props;

  console.log(props, "props");
  const initialFormValues: IPosLocalBasicFormValues = {
    deliveryPrice: 0,
  };
  const [formData, setFormData] = useState<IPosLocalBasicFormValues>(
    initialFormValues
  );
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      deliveryPrice,
    });
  }, [deliveryPrice]);
  const formikRef = useRef<FormikValues>();
  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const NewUserSchema = Yup.object().shape({
    deliveryPrice: Yup.string().required("Pole wymagane"),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Dane restauracji">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={NewUserSchema}
          onSubmit={(
            values: IPosLocalBasicFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item
                label="Cena dostawy"
                name="deliveryPrice"
                labelCol={{ span: 24 }}
                required
                rules={[{ required: true }]}
              >
                <Input name="deliveryPrice" type="number" />
              </Form.Item>

              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default PosLocalBasicForm;
