import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ConfigProvider } from "antd";

import { Helmet } from "react-helmet";

import locale from "antd/es/locale/pl_PL";
import moment from "moment";
import "moment/locale/pl";

import Login from "./components/Auth/Login";
import "./app.scss";
import PrivateRoute from "./components/PrivateRoute";
import DashboardBase from "./components/DashboardBase";
import DashboardPage from "./components/Pages/Dashboard";
import UsersPage from "./components/Pages/Users";
import TaskGroupsPage from "./components/Pages/TicketGroups";
import store from "./reducers/store";
import UserEditPage from "./components/Pages/Users/edit";
import TicketGroupEdit from "./components/Pages/TicketGroups/edit";
import HomePage from "./components/Pages/Home";
import TaskGroupPage from "./components/Pages/TicketGroup";
import ScrollToTop from "./components/Shared/ScrollToTop";
import TicketPage from "./components/Pages/Ticket";
import NewTicket from "./components/Pages/Ticket/new";
import EditTicket from "./components/Pages/Ticket/edit";
import CalendarPage from "./components/Pages/Calendar";
import TicketGroupsWorktimesPage from "./components/Pages/TicketGroupsWorktimes";
import TicketGroupWorktimesPage from "./components/Pages/TicketGroupWorktimes";
import TicketWorktimesPage from "./components/Pages/TicketWorktimes";
import ArchivePage from "./components/Pages/Archive";
import NotificationsPage from "./components/Pages/Notifications";
import AssignedPage from "./components/Pages/Assigned";
import ChatPage from "./components/Pages/Chat";
import useIsMobile from "./hooks/useIsMobile";
import EmptyPage from "./components/DashboardBase/EmptyPage";
import AudioService from "./services/audio-service";
import DuplicateTicket from "./components/Pages/Ticket/duplicate";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPasswordForm from "./components/Auth/ResetPassword/ResetPasswordForm";
import ResetPassword from "./components/Auth/ResetPassword";
import UserProfilePage from "./components/Pages/Profile";
import Invite from "./components/Auth/Invite";
import LocalsPage from "./components/Pages/Locals";
import LocalEditPage from "./components/Pages/Locals/edit";
import DeliveriesPage from "./components/Pages/Deliveries";
import SummaryPage from "./components/Pages/Summary";
import SettingsPage from "./components/Pages/Settings";

moment.locale("pl");

function App(): JSX.Element {
  AudioService.getInstance();

  return (
    <Provider store={store}>
      <Router>
        <Helmet title="ABS DRIVE" defer={false} />
        <ScrollToTop />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/password_reset/:token">
            <ResetPassword />
          </Route>
          <Route path="/invite/:token">
            <Invite />
          </Route>
          <Route path="/" exact>
            <PrivateRoute>
              <DashboardBase title="Strona główna" subtitle="Panel zarządzania">
                <DashboardPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/users" exact>
            <PrivateRoute>
              <DashboardBase title="Kierowcy" subtitle="Panel zarządzania">
                <UsersPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/users/edit/:id/" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja użytkownika"
                subtitle="Panel zarządzania"
              >
                <UserEditPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/locals" exact>
            <PrivateRoute>
              <DashboardBase title="Restauracje" subtitle="Panel zarządzania">
                <LocalsPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/deliveries" exact>
            <PrivateRoute>
              <DashboardBase title="Restauracje" subtitle="Panel zarządzania">
                <DeliveriesPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/summary" exact>
            <PrivateRoute>
              <DashboardBase title="Raporty" subtitle="Panel zarządzania">
                <SummaryPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/settings" exact>
            <PrivateRoute>
              <DashboardBase title="Ustawienia" subtitle="Panel zarządzania">
                <SettingsPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/locals/edit/:id/" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja restauracji"
                subtitle="Panel zarządzania"
              >
                <LocalEditPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
