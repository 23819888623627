import React, { MutableRefObject, useRef, useState } from "react";
import { Checkbox, Form, Input, SubmitButton } from "formik-antd";
import { Alert, Button, Card, Col, Row, Spin } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { generateRandomString } from "../../../../../utils/common";
import {
  IUserPasswordForm,
  IUserPasswordFormValues,
} from "../../../../../types/user";

interface IUserPasswordFormProps {
  isLoading: boolean;
  onSave: (
    values: IUserPasswordFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserPasswordFormValues>) => void
  ) => void;
}

const UserPasswordForm = (props: IUserPasswordFormProps) => {
  const { isLoading, onSave } = props;
  const initialFormValues: IUserPasswordFormValues = {
    password: "",
  };
  const [formData, setFormData] = useState<IUserPasswordForm>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);
  const formikRef = useRef<FormikValues>();
  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const UserPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Hasło jest za krótkie. Minimum 8 znaków")
      .required("Pole wymagane"),
  });
  return (
    <Spin spinning={isLoading}>
      <Card title="Nowe hasło">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={UserPasswordSchema}
          onSubmit={(
            values: IUserPasswordFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item
                label="Hasło"
                name="password"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Wprowadź hasło!" }]}
                required
              >
                <Row>
                  <Col xs={14} md={18}>
                    <Input name="password" style={{ flex: 1 }} />
                  </Col>
                  <Col xs={10} md={6}>
                    <Button
                      onClick={generatePassword}
                      icon={<SettingOutlined />}
                      style={{ marginLeft: 8 }}
                      block
                    >
                      Generuj
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              {/* <Form.Item name="sendCredentials" valuePropName="checked"> */}
              {/*  <Checkbox name="sendCredentials">Wyślij login i hasło</Checkbox> */}
              {/* </Form.Item> */}
              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default UserPasswordForm;
