import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Grid, Row, Tabs } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import {
  createUser,
  softDeleteUser,
  getTags,
  getUsersAdmin,
} from "../../../actions/users";

import LocalsList from "./list";
import NewLocal from "./new";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  createLocal,
  getLocals,
  softDeleteLocal,
} from "../../../actions/locals";

const { TabPane } = Tabs;

interface ILocalsPageProps {
  locals: any[];

  getLocalsAction: () => void;
  createLocalAction: (local: any) => Promise<any>;
  deleteLocalAction: (id: string) => Promise<any>;
  isRequest: boolean;
  isCreateRequest: boolean;
  isDeleteRequest: boolean;
}

const LocalsPage = (
  props: PropsWithChildren<ILocalsPageProps>
): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  const isMobile = useIsMobile();

  const {
    locals,
    isRequest,
    isCreateRequest,
    isDeleteRequest,
    createLocalAction,
    deleteLocalAction,
    getLocalsAction,
  } = props;
  const handleCancel = () => {
    setVisible(false);
  };

  const onCreate = (
    values: any,
    callbackResetForm: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createLocalAction({
      ...values,
    })
      .then((response: AxiosResponse) => {
        setVisible(false);
        props.getLocalsAction();
        setTimeout(() => props.getLocalsAction(), 500);
        callbackResetForm();
        openNotificationWithIcon("success", "Restauracja dodana");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
        if (err.response?.status === 409) {
          openNotificationWithIcon("error", "Restauracja istnieje.");
        }
      });
  };

  const onDelete = (user: any, callbackHidePopover: () => void) => {
    deleteLocalAction(user.id)
      .then(() => {
        openNotificationWithIcon("success", "Restauracja usunięty");
        callbackHidePopover();
      })
      .catch((error: AxiosError) => {
        callbackHidePopover();
      });
  };

  useEffect(() => {
    props.getLocalsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Can renderError type="is_admin">
      <div className="users-page">
        <MetaTitle title="Kierowcy" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <div className="users-page__header-actions">
              <Button type="primary" onClick={() => setVisible(true)}>
                Nowa restauracja
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <LocalsList
                users={locals}
                isRequest={isRequest}
                isDeleteRequest={isDeleteRequest}
                onDelete={onDelete}
              />
            </Card>
          </Col>
        </Row>
        <NewLocal
          onCancel={() => {
            setVisible(false);
          }}
          visible={visible}
          isRequest={isCreateRequest}
          onCreate={onCreate}
          tags={[]}
        />
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getLocalsAction: getLocals,
  createLocalAction: createLocal,
  deleteLocalAction: softDeleteLocal,
};

const mapStateToProps = (state: AppState) => {
  return {
    locals: state.locals.locals,
    isRequest: state.locals.isRequest,
    isCreateRequest: state.locals.isCreateRequest,
    isDeleteRequest: state.locals.isRemoveRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalsPage);
