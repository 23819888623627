// TODO: display errors while name is not unique (backend/front - create/edit)
import React, { PropsWithChildren, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Col, Divider, Empty, Row, Spin, Tabs } from "antd";
import { RcFile } from "antd/es/upload";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  bulkAddTicketGroupMembership,
  editUser,
  getTags,
  getTicketGroupMembership,
  getUser,
  removeAvatar,
  setUser,
  uploadAvatar,
} from "../../../actions/users";
import { getRolesGroups } from "../../../actions/roles";
import UserBasicForm from "./EditUserForm/BasicForm";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import UserPasswordForm from "./EditUserForm/PasswordForm";
import UserSettingsForm from "./EditUserForm/SettingsForm";
import UserPermissionsForm from "./EditUserForm/PermissionsForm";
import { RoleGroup, RoleModel } from "../../../reducers/roles";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import TicketGroupMemberForm from "../TicketGroups/EditTicketGroupForm/MemberForm";
import TicketGroupAddMembersForm, {
  ITicketGroupAddMember,
  ITicketGroupAddMembersFormValues,
} from "../TicketGroups/EditTicketGroupForm/AddMemberForm";
import {
  bulkAddTicketGroupMembers,
  getTicketGroups,
  removeTicketGroupMember,
} from "../../../actions/ticketgroups";
import UserGroupMembershipForm from "./EditUserForm/MembershipForm";
import UserAddMembershipForm, {
  IUserAddMembership,
  IUserAddMembershipFormValues,
} from "./EditUserForm/AddMembershipForm";
import { setCurrentEditedUser } from "../../../actions/global";
import UserHolidaysForm from "./EditUserForm/HolidaysForm";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}
interface IUsersPageProps {
  user?: any;
  tags: any[];
  groupsMembership: any[];
  groups: any[];
  roles: RoleGroup[];
  isFetchUserRequest: boolean;
  isFetchTicketGroupsRequest: boolean;
  displayNotification: boolean;
  isEditRequest?: boolean;
  isGroupsMembershipRequest: boolean;
  getUser: (id: string | number) => void;
  getGroupsMembershipAction: (id: string | number) => void;
  removeTicketGroupMemberAction: (
    id: string | number,
    groupId: string | number
  ) => Promise<any>;
  getTagsAction: () => void;
  getRolesGroupsAction: () => void;
  setUserAction: (user: any) => void;
  setCurrentEditedUserAction: (name: string | null) => void;
  getTicketGroupsAction: (noLoader: boolean) => Promise<void>;
  uploadAvatarAction: (
    id: string | number,
    file: string | RcFile | Blob
  ) => Promise<any>;
  editUserAction: (id: string | number, user: any) => Promise<any>;
  removeAvatarAction: (id: string | number) => Promise<any>;
  bulkAddTicketGroupMembershipAction: (
    id: string | number,
    groups: number[]
  ) => Promise<any>;
}

const UserEditPage = (
  props: PropsWithChildren<IUsersPageProps>
): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();
  const {
    user,
    tags,
    roles,
    groups,
    groupsMembership,
    getTicketGroupsAction,
    bulkAddTicketGroupMembershipAction,
    removeTicketGroupMemberAction,
    setCurrentEditedUserAction,
    setUserAction,
    uploadAvatarAction,
    editUserAction,
    removeAvatarAction,
    isFetchUserRequest,
    isGroupsMembershipRequest,
    isFetchTicketGroupsRequest,
    getRolesGroupsAction,
    getGroupsMembershipAction,
    getTagsAction,
    displayNotification,
  } = props;

  const onSave = (
    values: any,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    editUserAction(id, {
      ...values,
    })
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        props.getUser(id);
        openNotificationWithIcon("success", "Dane kierowcy zapisane");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onUpload = (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => {
    uploadAvatarAction(id, file)
      .then((response: AxiosResponse) => {
        onRequestComplete(response.data.img);
        openNotificationWithIcon("success", "Avatar został zapisany");
      })
      .catch((error: AxiosError) => {
        onRequestComplete();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie zapisu avatara"
          );
        }
      });
  };

  const onAvatarRemove = (setRequestCompleted: () => void) => {
    removeAvatarAction(id)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Avatar został usunięty");
        setRequestCompleted();
      })
      .catch((error: AxiosError) => {
        setRequestCompleted();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie usuwania avataru"
          );
        }
      });
  };

  const onMembershipAdd = (
    values: IUserAddMembershipFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    const newMemberships: number[] = values.memberships.map(
      (group: IUserAddMembership) => group.value
    );

    bulkAddTicketGroupMembershipAction(id, newMemberships)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon(
          "success",
          "Przydzielone działy zaktualizowane."
        );
      })
      .catch((err: AxiosError) => {
        console.error(err);
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
        if (err.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            "Wystąpił bład. Wybrany dział jest już przydzielony"
          );
        }
      });
  };
  const onMembershipRemove = (
    groupId: string | number,
    callbackRequestCompleted: () => void
  ) => {
    removeTicketGroupMemberAction(groupId, id)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Usunięto przydzielenie do działu");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
      });
  };

  useEffect(() => {
    if (id) props.getUser(id);

    return () => {
      setCurrentEditedUserAction(null);
      setUserAction(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.name) {
      setCurrentEditedUserAction(`${user.name}`);
    }
  }, [setCurrentEditedUserAction, user.name]);

  return (
    <Can renderError type="is_admin">
      <div className="user-edit-page">
        <MetaTitle
          title={
            user.name
              ? `${user.name} ${user.surname} - Edycja użytkownika`
              : "Edycja użytkownika"
          }
          displayBadge={displayNotification}
        />
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Dane podstawowe" key="1">
                  <Row gutter={16} align="middle" justify="center">
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      <UserBasicForm
                        img={user.img}
                        email={user.email}
                        name={user.name}
                        surname={user.surname}
                        tag={user.tag}
                        tags={tags}
                        phone={user.phone}
                        typeAccount={user.typeAccount}
                        isLoading={isFetchUserRequest}
                        onUpload={onUpload}
                        onSave={onSave}
                        onAvatarRemove={onAvatarRemove}
                      />
                      <Divider />
                      <UserPasswordForm
                        isLoading={isFetchUserRequest}
                        onSave={onSave}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getUser,
  uploadAvatarAction: uploadAvatar,
  removeAvatarAction: removeAvatar,
  editUserAction: editUser,
  getTagsAction: getTags,
  getRolesGroupsAction: getRolesGroups,
  getTicketGroupsAction: getTicketGroups,
  getGroupsMembershipAction: getTicketGroupMembership,
  removeTicketGroupMemberAction: removeTicketGroupMember,
  bulkAddTicketGroupMembershipAction: bulkAddTicketGroupMembership,
  setCurrentEditedUserAction: setCurrentEditedUser,
  setUserAction: setUser,
};

const mapStateToProps = (state: AppState) => {
  return {
    user: state.users.user,
    tags: state.users.tags,
    groups: state.ticketgroups.ticketgroups,
    isFetchTicketGroupsRequest: state.ticketgroups.isFetchTicketGroupsRequest,
    groupsMembership: state.users.groupsMembership,
    isFetchUserRequest: state.users.isFetchUserRequest,
    isGroupsMembershipRequest: state.users.isGroupsMembershipRequest,
    isEditRequest: state.users.isEditRequest,
    roles: state.roles.roles,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);
