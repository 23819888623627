import React, { useEffect, useState } from "react";
import { Button, Card, Col, Grid, Row } from "antd";
import {
  FieldTimeOutlined,
  HddOutlined,
  HourglassOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import { FormikErrors } from "formik";
import { AxiosError, AxiosResponse } from "axios";
import StatisticsBox from "../../Shared/StatisticBox";
import ProjectsWorkTime from "../../Shared/ProjectsWorkTime";
import UsersOnline from "../../Shared/UsersOnline";
import ActiveTasks from "../../Shared/ActiveTasks";

import DashboardService from "../../../services/dashboard-service";
import {
  formatSeconds,
  formatSecondsToFootballMatch,
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import TicketGroupsWorktimesPage from "../TicketGroupsWorktimes";
import { MetaTitle } from "../../Shared/MetaTitle";
import { createTicket } from "../../../actions/tickets";
import { AppState } from "../../../reducers";
import UsersWorktimes from "../../Shared/UsersWorktimes";
import Can from "../../Shared/Can";
import NewLocal from "../Locals/new";
import OrdersService from "../../../services/orders-service";
import NewOrderForm from "./newOrder";
import EditTime from "./editTime";

const DashboardPage = (props: any): JSX.Element => {
  const [stats, setStats] = useState<any>(null);
  const [isStatsLoading, setStatsLoading] = useState(false);

  const [worktimes, setWorktimes] = useState(null);
  const [isWorktimesLoading, setWorktimesLoading] = useState(false);

  const [users, setUsers] = useState(null);
  const [isUsersLoading, setUsersLoading] = useState(false);

  const [restaurant, setRestaurant] = useState<any>(null);
  const [isActiveTasksLoading, setActiveTasksLoading] = useState(false);

  const { displayNotification } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editTime, setEditTime] = useState<{
    id: string;
    take_away_time: number;
  } | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  const screens = useBreakpoint();
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getRestaurant();
  }, []);

  const currentMonth = moment().format("MMMM YYYY");
  const matches = formatSecondsToFootballMatch(
    stats?.worktimeCurrentMonth || 0
  );

  const countEasterEggsArray = [
    {
      count: 200,
      color: "brown",
      url: "https://www.youtube.com/watch?v=DjDSUqTcrv4",
    },
    {
      count: 400,
      color: "silver",
      url: "https://www.youtube.com/watch?v=0ov53YrKl8c",
    },
    {
      count: 600,
      color: "gold",
      url: "https://www.youtube.com/watch?v=QdSC5NDaE48",
    },
    {
      count: 800,
      color: "#00c3ff",
      url: "https://www.youtube.com/watch?v=rUMozsSpVVA",
    },
    {
      count: 1000,
      color: "red",
      url: "https://www.youtube.com/watch?v=fDtZgnXCm1Q",
    },
  ];

  const hoursEasterEggsArray = [
    {
      count: 1000,
      color: "gold",
      url: "https://www.youtube.com/watch?v=5ZCgbGgA-_8",
    },
  ];

  const ticketCountEasterEgg = countEasterEggsArray
    .reverse()
    .filter((item) => stats?.ticketCount >= item.count);

  const hoursEasterEgg = hoursEasterEggsArray
    .reverse()
    .filter(
      (item) =>
        Math.floor((stats?.worktimeCurrentMonth || 0) / 3600) >= item.count
    );

  const getRestaurant = () => {
    OrdersService.getRestaurant().then((res) => {
      setRestaurant(res.data);
    });
  };

  const handleTakeAwayEdit = (id: any, take_away_time: any) => {
    setEditTime({ id, take_away_time });
  };
  const onCreate = (
    values: any,
    callbackResetForm: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    OrdersService.createOrder({
      ...values,
      takeAway: moment(values.takeAway).unix() * 1000,
    })
      .then((response: AxiosResponse) => {
        setModalVisible(false);
        setTimeout(() => setRefresh(new Date().getTime()), 500);
        callbackResetForm();
        openNotificationWithIcon("success", "Dostawa dodana");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
        if (err.response?.status === 409) {
          openNotificationWithIcon("error", "Dostawa istnieje.");
        }
      });
  };

  const onEdit = (delay: number) => {
    OrdersService.editDelay(
      editTime?.id,
      delay ? moment(delay).unix() * 1000 : null
    )
      .then((response: AxiosResponse) => {
        setEditTime(null);
        setTimeout(() => setRefresh(new Date().getTime()), 500);
        openNotificationWithIcon("success", "Opóźnienie ustawione");
      })
      .catch((err: AxiosError) => {
        openNotificationWithIcon("success", "Wystąpił bląd");
      });
  };

  return (
    <div className="dashboard-page">
      <MetaTitle title="Kokpit" displayBadge={displayNotification} />

      <Row gutter={16} className="dashboard-page__worktime">
        <Can type="is_user">
          <Col span={24}>
            <div className="users-page__header-actions">
              <Button type="primary" onClick={() => setModalVisible(true)}>
                Nowa dostawa
              </Button>
            </div>
          </Col>
        </Can>

        <Col xs={24} sm={24} md={24} lg={24}>
          <TicketGroupsWorktimesPage
            isMobile={isMobile}
            refresh={refresh}
            handleEditTime={handleTakeAwayEdit}
          />
        </Col>
      </Row>

      {/* <Row gutter={16} className="dashboard-page__worktime"> */}
      {/*  <Col xs={24} sm={24} md={24} lg={24}> */}
      {/*    <UsersWorktimes isMobile={isMobile} /> */}
      {/*  </Col> */}
      {/* </Row> */}

      <NewOrderForm
        address={restaurant?.address}
        onCancel={() => {
          setModalVisible(false);
        }}
        visible={modalVisible}
        isRequest={false}
        onCreate={onCreate}
      />

      <EditTime
        order={editTime}
        onCancel={() => {
          setEditTime(null);
        }}
        visible={editTime != null}
        isRequest={false}
        onEdit={onEdit}
      />
    </div>
  );
};

const mapDispatchToProps = {
  createTicketAction: createTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
