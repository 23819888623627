import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";

class LocalsService {
  getLocals = async (): Promise<AxiosResponse> => {
    const response = await api.get("locals");
    return response;
  };

  getLocal = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`locals/${id}`);
    return response;
  };

  // TODO: dodać typ usera (form)
  createLocal = async (local: any): Promise<AxiosResponse> => {
    const response = await api.post(`locals`, local);
    return response;
  };

  editLocal = async (
    id: number | string,
    local: any
  ): Promise<AxiosResponse> => {
    const response = await api.put(`locals/${id}`, local);
    return response;
  };

  deleteLocal = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`locals/${id}`);
    return response;
  };

  softDelete = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`locals/${id}`);
    return response;
  };
}

export default new LocalsService();
