import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import {
  Select,
  Card,
  Col,
  Empty,
  Row,
  Space,
  Tooltip,
  Form,
  Button,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { Helmet } from "react-helmet";
import FormItemLabel from "antd/es/form/FormItemLabel";
import { AppState } from "../../../reducers";
import List from "./list";
import CalendarPicker, {
  CalendarFilterOptions,
} from "../CustomFilters/CalendarPicker";
import { getTicketGroupWorktimesSummary } from "../../../actions/worktimes";
import { getTicketGroups } from "../../../actions/ticketgroups";
import WorktimeGroups from "../CustomFilters/WorktimeGroups";
import WorktimeService from "../../../services/worktime-service";
import Can from "../Can";
import { getUsers } from "../../../actions/users";
import { AccountTypeWithHighlight } from "../../../types/user";
import OrdersService from "../../../services/orders-service";
import LocalsService from "../../../services/locals-service";

const { Option, OptGroup } = Select;

const UsersWorktimes = (props: PropsWithChildren<any>): JSX.Element => {
  const [filterVisible, setFilterVisible] = React.useState(false);
  const [deadlinesVisible, setDeadlinesVisible] = React.useState(false);
  const [worktimesList, setWorktimesList] = React.useState<any>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [locals, setLocals] = React.useState<any[]>([]);
  const [isLocalLoading, setLocalLoading] = React.useState<boolean>(false);
  const {
    worktimes,
    groups,
    groupsRequest,
    worktimesRequest,
    usersRequest,
    users,
    getUsersAction,
    getTicketGroupsAction,
    isMobile,
  } = props;

  const [filter, setFilter] = React.useState<{
    begin: number;
    end: number;
    groups: string[];
    users: number[];
    status: string;
    selectedType: CalendarFilterOptions;
  }>({
    begin: moment().startOf("week").unix(),
    end: moment().endOf("week").unix(),
    groups: [],
    users: [],
    status: "all",
    selectedType: CalendarFilterOptions.THIS_WEEK,
  });

  const filterRef = useRef();

  const getLocals = () => {
    setLocalLoading(true);
    LocalsService.getLocals()
      .then((res) => {
        setLocals(res.data);
      })
      .finally(() => {
        setLocalLoading(false);
      });
  };

  useEffect(() => {
    // getTicketGroupsAction(false);
    getLocals();
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    setLoading(true);
    OrdersService.getSummary(
      filter.begin * 1000,
      filter.end * 1000,
      filter.groups
    )
      .then((response) => {
        console.log(response.data, response);
        setWorktimesList(response.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = () => {
    setLoading(true);

    OrdersService.getSummaryPdf(
      filter.begin * 1000,
      filter.end * 1000,
      filter.groups
    )
      .then((response) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const filterUsers = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    const userObj = JSON.parse(optionOrGroup.value);

    const haveTag = userObj.tag?.find((tag: any) =>
      tag.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
      optionOrGroup.children[0]?.toLowerCase().includes(search.toLowerCase()) ||
      haveTag
    );
  }, []);

  const options = locals.map((local: any) => {
    return {
      value: local.id,
      label: local.name,
    };
  });

  return (
    <>
      <div className="ticket-group-page">
        <Row gutter={[0, 0]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false} title="Rozliczenia">
                <div className="users-worktimes__filters">
                  <Space
                    direction="vertical"
                    className="users-worktimes__calendar"
                  >
                    <small>Data</small>
                    <CalendarPicker
                      ref={filterRef}
                      begin={filter.begin}
                      end={filter.end}
                      selectedType={filter.selectedType}
                      onChange={(
                        begin: Moment,
                        end: Moment,
                        selectedType: CalendarFilterOptions
                      ) => {
                        setFilter({
                          begin: begin.unix(),
                          end: end.unix(),
                          groups: [...filter.groups],
                          users: [...filter.users],
                          status: filter.status,
                          selectedType,
                        });
                      }}
                    />
                  </Space>

                  <Space
                    direction="vertical"
                    className="users-worktimes__users"
                  >
                    <small>Restauracja</small>
                    <Select
                      id="filterUsers"
                      style={{
                        width: "100%",
                      }}
                      dropdownStyle={{ minHeight: 300 }}
                      maxTagCount="responsive"
                      maxTagTextLength={15}
                      showSearch
                      getPopupContainer={(trigger) =>
                        trigger.parentNode as HTMLElement
                      }
                      placeholder="Wybierz lub pozostaw puste"
                      optionFilterProp="children"
                      optionLabelProp="label"
                      options={options}
                      notFoundContent={<Empty description="Brak restauracji" />}
                      mode="multiple"
                      filterOption={filterUsers}
                      onChange={(selected: any[]) => {
                        const selectedUsersIds = selected.map((value) => {
                          return value;
                        });
                        setFilter((prevState) => {
                          return {
                            ...prevState,
                            groups: selectedUsersIds,
                          };
                        });
                      }}
                    >
                      {/* {locals.length && */}
                      {/*  locals.map((local: any, index: number) => { */}
                      {/*    return ( */}
                      {/*      <Option */}
                      {/*        value={JSON.stringify({ */}
                      {/*          key: local.id, */}
                      {/*          label: `${local.name}`, */}
                      {/*        })} */}
                      {/*        key={local.id} */}
                      {/*        title="asd" */}
                      {/*        label={`${local.name}`} */}
                      {/*      > */}
                      {/*        {local.name} */}
                      {/*      </Option> */}
                      {/*    ); */}
                      {/*  })} */}
                    </Select>
                  </Space>
                  <Space>
                    <Button
                      loading={worktimesRequest}
                      type="primary"
                      className="users-worktimes__submit"
                      onClick={handleSubmit}
                    >
                      Wyszukaj
                    </Button>

                    <Button
                      loading={worktimesRequest}
                      type="primary"
                      className="users-worktimes__submit"
                      onClick={handleDownload}
                    >
                      Pobierz
                    </Button>
                  </Space>
                </div>
              </Card>
              <Card bordered={false}>
                <List
                  data={worktimesList?.data || []}
                  users={worktimesList?.summary?.users || []}
                  total={worktimesList?.totals}
                  isRequest={isLoading}
                  isMobile={isMobile}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getTicketGroupsAction: getTicketGroups,
  getUsersAction: getUsers,
};

const mapStateToProps = (state: AppState) => {
  return {
    worktimes: state.worktime.users,
    groups: state.ticketgroups.ticketgroups,
    users: state.users.users,
    usersRequest: state.users.isRequest,
    groupsRequest: state.ticketgroups.isFetchTicketGroupsRequest,
    worktimesRequest: state.worktime.usersRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersWorktimes);
