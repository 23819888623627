import React, {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import { Checkbox, Form, Input, Select } from "formik-antd";
import { Alert, Button, Col, Modal, Row } from "antd";
import { Formik, FormikValues } from "formik";
import { SettingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { generateRandomString } from "../../../utils/common";
import { INewUserFormProps, INewUserFormValues } from "../../../types/user";

const NewUserForm: React.FC<INewUserFormProps> = (
  props: PropsWithChildren<INewUserFormProps>
): JSX.Element => {
  const { visible, onCancel, onCreate, isRequest, tags } = props;

  const formikRef = useRef<FormikValues>();

  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const initialFormValues: INewUserFormValues = {
    email: "",
    name: "",
    surname: "",
    tag: null,
    phone: "",
    password: "",
  };

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required("Pole wymagane"),
    phone: Yup.string()
      .required("Pole wymagane")
      .matches(/^[0-9]+$/, "Telefon powinien składać się z samych cyfr")
      .min(9, "Telefon powinien składać się z 9 cyfr")
      .max(9, "Telefon powinien składać się z 9 cyfr")
      .nullable(),
    password: Yup.string().required("Pole wymagane"),
  });

  return (
    <Modal
      visible={visible}
      title="Nowy kierowca"
      okText="Dodaj"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={isRequest}
      onOk={() => {
        if (formikRef.current) {
          formikRef.current.setFieldTouched("tag");
          formikRef.current.handleSubmit();
        }
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={initialFormValues}
        validationSchema={NewUserSchema}
        validateOnBlur
        onSubmit={(values: INewUserFormValues, { resetForm, setErrors }) => {
          onCreate(values, resetForm, setErrors);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Form.Item
              label="Login"
              name="name"
              required
              labelCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Input name="name" />
            </Form.Item>
            <Form.Item
              label="Telefon"
              name="phone"
              required
              labelCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Input name="phone" />
            </Form.Item>
            <Form.Item
              label="Hasło"
              name="password"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Wprowadź hasło!" }]}
              required
            >
              <Row>
                <Col span={18}>
                  <Input name="password" style={{ flex: 1 }} />
                </Col>
                <Col span={6}>
                  <Button
                    onClick={generatePassword}
                    icon={<SettingOutlined />}
                    style={{ marginLeft: 8 }}
                    block
                  >
                    Generuj
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      />
    </Modal>
  );
};

export default NewUserForm;
