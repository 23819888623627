import React, { cloneElement, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import { AccountType } from "../../../types/user";
import NoPermissions from "../NoPermissions";
import { TicketStatus, TicketValuationStatus } from "../../../types/ticket";

type PropsType = {
  children: JSX.Element;
  type: string;
  roles?: any[];
  renderError?: boolean;
  entity?: any;
};

const hasPermission = ({
  userId,
  permissions,
  accountType,
  type,
  forbidden,
  entity,
}: any) => {
  switch (type) {
    case "is_admin":
      return accountType === AccountType.Administrator;
    case "is_user":
      return accountType === AccountType.Użytkownik;

    default:
      return false;
  }

  return false;
};

const Can = (props: PropsWithChildren<PropsType>): JSX.Element | null => {
  const user = useSelector((state: AppState) => state.auth.logged);
  const forbidden = useSelector(
    (state: AppState) => state.global.currentEntityForbidden
  );

  const { roles, type, children, renderError, entity, ...rest } = props;
  const accountType = user?.admin ? 2 : 1;
  const permissionGranted = hasPermission({
    userId: user?.id,
    permissions: user?.role,
    accountType,
    type,
    forbidden,
    entity,
  });

  if (!permissionGranted && renderError) return <NoPermissions />;

  if (!permissionGranted) return null;

  return cloneElement(children, { ...rest });
};

export default Can;
