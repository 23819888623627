/* eslint-disable prettier/prettier */

export const FETCH_REQUEST = "FETCH_REQUEST";
export const SET_USERS = "SET_USERS";
export const FETCH_FAILED = "FETCH_FAILED";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const CREATE_REQUEST_SUCCESS = "CREATE_REQUEST_SUCCESS";
export const CREATE_REQUEST_FAILED = "CREATE_REQUEST_FAILED";
export const REMOVE_REQUEST = "REMOVE_REQUEST";
export const REMOVE_REQUEST_SUCCESS = "REMOVE_REQUEST_SUCCESS";
export const REMOVE_REQUEST_FAILED = "REMOVE_REQUEST_FAILED";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const SET_USER = "SET_USER";
export const FETCH_USER_REQUEST_SUCCESS = "FETCH_USER_REQUEST_SUCCESS";
export const FETCH_USER_REQUEST_FAILED = "FETCH_USER_REQUEST_FAILED";
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_REQUEST_SUCCESS = "EDIT_USER_REQUEST_SUCCESS";
export const EDIT_USER_REQUEST_FAILED = "EDIT_USER_REQUEST_FAILED";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const LOGOUT = "LOGOUT";
export const SET_LOGGED_USER = "SET_LOGGED_USER";

export const FETCH_ROLES_REQUEST = "FETCH_ROLES_REQUEST";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILED = "FETCH_ROLES_FAILED";
export const SET_ROLES = "SET_ROLES";

export const FETCH_TICKET_GROUPS_SUCCESS = "FETCH_TICKET_GROUPS_SUCCESS";
export const FETCH_TICKET_GROUPS_FAILED = "FETCH_TASK_GROUPS_FAILED";
export const FETCH_TICKET_GROUPS_REQUEST = "FETCH_TASK_GROUPS_REQUEST";

export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILED = "FETCH_NOTES_FAILED";
export const FETCH_NOTES = "FETCH_NOTES";

export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAILED = "UPDATE_NOTES_FAILED";
export const UPDATE_NOTES = "UPDATE_NOTES";

export const CLEAR_TICKET_GROUPS_STATE = "CLEAR_TICKET_GROUPS_STATE";
export const CLEAR_TICKET_GROUP_STATE = "CLEAR_TICKET_GROUPS_STATE";

export const FETCH_TICKET_GROUP_REQUEST = "FETCH_TICKET_GROUP_REQUEST";
export const FETCH_TICKET_GROUP_SUCCESS = "FETCH_TICKET_GROUP_SUCCESS";
export const FETCH_TICKET_GROUP_FAILED = "FETCH_TICKET_GROUP_FAILED";
export const SET_TICKET_GROUP = "SET_TICKET_GROUP";

export const UPDATE_TICKET_GROUPS_POSITIONS_SUCCESS =
  "UPDATE_TICKET_GROUPS_POSITIONS_SUCCESS";
export const UPDATE_TICKET_GROUPS_POSITIONS_FAILED =
  "UPDATE_TICKET_GROUPS_POSITIONS_FAILED";
export const UPDATE_TICKET_GROUPS_POSITIONS_REQUEST =
  "UPDATE_TICKET_GROUPS_POSITIONS_REQUEST";

export const CREATE_TICKET_GROUP_REQUEST = "CREATE_TICKET_GROUP_REQUEST";
export const CREATE_TICKET_GROUP_REQUEST_SUCCESS =
  "CREATE_TICKET_GROUP_REQUEST_SUCCESS";
export const CREATE_TICKET_GROUP_REQUEST_FAILED =
  "CREATE_TICKET_GROUP_REQUEST_FAILED";

export const SOFTDELETE_TICKET_GROUPS_SUCCESS =
  "SOFTDELETE_TICKET_GROUPS_SUCCESS";
export const SOFTDELETE_TICKET_GROUPS_FAILED =
  "SOFTDELETE_TICKET_GROUPS_FAILED";
export const SOFTDELETE_TICKET_GROUPS_REQUEST =
  "SOFTDELETE_TICKET_GROUPS_REQUEST";

export const EDIT_TICKET_GROUP_REQUEST = "EDIT_TICKET_GROUP_REQUEST";
export const EDIT_TICKET_GROUP_SUCCESS = "EDIT_TICKET_GROUP_SUCCESS";
export const EDIT_TICKET_GROUP_FAILED = "EDIT_TICKET_GROUP_FAILED";

export const FETCH_TICKET_GROUPS_MEMBERS_SUCCESS =
  "FETCH_TICKET_GROUPS_MEMBERS_SUCCESS";
export const FETCH_TICKET_GROUPS_MEMBERS_FAILED =
  "FETCH_TICKET_GROUPS_MEMBERS_FAILED";
export const FETCH_TICKET_GROUPS_MEMBERS_REQUEST =
  "FETCH_TICKET_GROUPS_MEMBERS_REQUEST";

export const FETCH_TICKET_GROUPS_LEADERS_SUCCESS =
  "FETCH_TICKET_GROUPS_LEADERS_SUCCESS";
export const FETCH_TICKET_GROUPS_LEADERS_FAILED =
  "FETCH_TICKET_GROUPS_LEADERS_FAILED";
export const FETCH_TICKET_GROUPS_LEADERS_REQUEST =
  "FETCH_TICKET_GROUPS_LEADERS_REQUEST";

export const BULK_ASSIGN_TICKET_GROUPS_LEADERS_SUCCESS =
  "BULK_ASSIGN_TICKET_GROUPS_LEADERS_SUCCESS";
export const BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED =
  "BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED";
export const BULK_ASSIGN_TICKET_GROUPS_LEADERS_REQUEST =
  "BULK_ASSIGN_TICKET_GROUPS_LEADERS_REQUEST";

export const UPDATE_TICKET_GROUPS_LEADER_SUCCESS =
  "UPDATE_TICKET_GROUPS_LEADER_SUCCESS";
export const UPDATE_TICKET_GROUPS_LEADER_FAILED =
  "UPDATE_TICKET_GROUPS_LEADER_FAILED";
export const UPDATE_TICKET_GROUPS_LEADER_REQUEST =
  "UPDATE_TICKET_GROUPS_LEADER_REQUEST";

export const DELETE_TICKET_GROUPS_LEADER_SUCCESS =
  "DELETE_TICKET_GROUPS_LEADER_SUCCESS";
export const DELETE_TICKET_GROUPS_LEADER_FAILED =
  "DELETE_TICKET_GROUPS_LEADER_FAILED";
export const DELETE_TICKET_GROUPS_LEADER_REQUEST =
  "DELETE_TICKET_GROUPS_LEADER_REQUEST";

export const BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS =
  "BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS";
export const BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED =
  "BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED";
export const BULK_ASSIGN_TICKET_GROUPS_MEMBERS =
  "BULK_ASSIGN_TICKET_GROUPS_MEMBERS";

export const DELETE_TICKET_GROUPS_MEMBER_SUCCESS =
  "DELETE_TICKET_GROUPS_MEMBER_SUCCESS";
export const DELETE_TICKET_GROUPS_MEMBER_FAILED =
  "DELETE_TICKET_GROUPS_MEMBER_FAILED";
export const DELETE_TICKET_GROUPS_MEMBER = "DELETE_TICKET_GROUPS_MEMBER";

export const DELETE_USER_GROUP_MEMBERSHIP = "DELETE_USER_GROUP_MEMBERSHIP";

export const FETCH_TICKET_GROUPS_HOME_SUCCESS =
  "FETCH_TICKET_GROUPS_HOME_SUCCESS";
export const FETCH_TICKET_GROUPS_HOME_FAILED =
  "FETCH_TICKET_GROUPS_HOME_FAILED";
export const FETCH_TICKET_GROUPS_HOME_REQUEST =
  "FETCH_TICKET_GROUPS_HOME_REQUEST";

export const FETCH_TICKET_GROUP_HOME_SUCCESS =
  "FETCH_TICKET_GROUP_HOME_SUCCESS";
export const FETCH_TICKET_GROUP_HOME_FAILED = "FETCH_TICKET_GROUP_HOME_FAILED";
export const FETCH_TICKET_GROUP_HOME_REQUEST =
  "FETCH_TICKET_GROUP_HOME_REQUEST";

export const FETCH_TICKET_GROUPS_TICKET_TYPES_SUCCESS =
  "FETCH_TICKET_GROUPS_TICKET_TYPES_SUCCESS";
export const FETCH_TICKET_GROUPS_TICKET_TYPES_FAILED =
  "FETCH_TICKET_GROUPS_TICKET_TYPES_FAILED";
export const FETCH_TICKET_GROUPS_TICKET_TYPES_REQUEST =
  "FETCH_TICKET_GROUPS_TICKET_TYPES_REQUEST";

export const SET_CURRENT_TICKET_TITLE = "SET_CURRENT_TICKET_TITLE";
export const SET_CURRENT_GROUP_TITLE = "SET_CURRENT_GROUP_TITLE";
export const SET_EDITED_USER = "SET_EDITED_USER";
export const SET_CURRENT_ENTITY_FORBIDDEN = "SET_CURRENT_ENTITY_FORBIDDEN";

export const FETCH_TICKET_REQUEST = "FETCH_TICKET_REQUEST";
export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
export const FETCH_TICKET_FAILED = "FETCH_TICKET_FAILED";

export const FETCH_TICKETS_REQUEST = "FETCH_TICKETS_REQUEST";
export const FETCH_TICKETS_SUCCESS = "FETCH_TICKETS_SUCCESS";
export const FETCH_TICKETS_FAILED = "FETCH_TICKETS_FAILED";

export const FETCH_TICKET_TYPES = "FETCH_TICKET_TYPES";
export const FETCH_TICKET_TYPES_SUCCESS = "FETCH_TICKET_TYPES_SUCCESS";
export const FETCH_TICKET_TYPES_FAILED = "FETCH_TICKET_TYPES_FAILED";

export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_REQUEST_SUCCESS = "CREATE_TICKET_REQUEST_SUCCESS";
export const CREATE_TICKET_REQUEST_FAILED = "CREATE_TICKET_REQUEST_FAILED";

export const UPDATE_TICKET_REQUEST = "UPDATE_TICKET_REQUEST";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILED = "UPDATE_TICKET_FAILED";

export const UPDATE_TICKET_DEADLINE_CALENDAR =
  "UPDATE_TICKET_DEADLINE_CALENDAR";
export const UPDATE_TICKET_DEADLINE_CALENDAR_SUCCESS =
  "UPDATE_TICKET_DEADLINE_CALENDAR_SUCCESS";
export const UPDATE_TICKET_DEADLINE_CALENDAR_FAILED =
  "UPDATE_TICKET_DEADLINE_CALENDAR_FAILED";

export const SET_TICKET = "SET_TICKET";

export const FETCH_TICKET_WORKTIMES_SHORT = "FETCH_TICKET_WORKTIMES_SHORT";
export const FETCH_TICKET_WORKTIMES_SHORT_SUCCESS =
  "FETCH_TICKET_WORKTIMES_SHORT_SUCCESS";
export const FETCH_TICKET_WORKTIMES_SHORT_FAILED =
  "FETCH_TICKET_WORKTIMES_SHORT_FAILED";

export const FETCH_TICKET_WORKTIMES = "FETCH_TICKET_WORKTIMES";
export const FETCH_TICKET_WORKTIMES_SUCCESS = "FETCH_TICKET_WORKTIMES_SUCCESS";
export const FETCH_TICKET_WORKTIMES_FAILED = "FETCH_TICKET_WORKTIMES_FAILED";

export const FETCH_USERS_WORKTIMES = "FETCH_USERS_WORKTIMES";
export const FETCH_USERS_WORKTIMES_SUCCESS = "FETCH_USERS_WORKTIMES_SUCCESS";
export const FETCH_USERS_WORKTIMES_FAILED = "FETCH_USERS_WORKTIMES_FAILED";

export const FETCH_TICKET_GROUP_WORKTIMES = "FETCH_TICKET_GROUP_WORKTIMES";
export const FETCH_TICKET_GROUP_WORKTIMES_SUCCESS =
  "FETCH_TICKET_GROUP_WORKTIMES_SUCCESS";
export const FETCH_TICKET_GROUP_WORKTIMES_FAILED =
  "FETCH_TICKET_GROUP_WORKTIMES_FAILED";

export const FETCH_TICKET_GROUP_WORKTIMES_SUMMARY =
  "FETCH_TICKET_GROUP_WORKTIMES_SUMMARY";
export const FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_SUCCESS =
  "FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_SUCCESS";
export const FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_FAILED =
  "FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_FAILED";

export const FETCH_USER_ACTIVE_WORKTIME = "FETCH_USER_ACTIVE_WORKTIME";
export const FETCH_USER_ACTIVE_WORKTIME_SUCCESS =
  "FETCH_USER_ACTIVE_WORKTIME_SUCCESS";
export const FETCH_USER_ACTIVE_WORKTIME_FAILED =
  "FETCH_USER_ACTIVE_WORKTIME_FAILED";

export const FETCH_USER_LAST_WORKTIME = "FETCH_USER_ACTIVE_WORKTIME";
export const FETCH_USER_LAST_WORKTIME_SUCCESS =
  "FETCH_USER_LAST_WORKTIME_SUCCESS";
export const FETCH_USER_LAST_WORKTIME_FAILED =
  "FETCH_USER_LAST_WORKTIME_FAILED";

export const FETCH_ALL_ACTIVE_WORKTIME = "FETCH_ALL_ACTIVE_WORKTIME";
export const FETCH_ALL_ACTIVE_WORKTIME_SUCCESS =
  "FETCH_ALL_ACTIVE_WORKTIME_SUCCESS";
export const FETCH_ALL_ACTIVE_WORKTIME_FAILED =
  "FETCH_ALL_ACTIVE_WORKTIME_FAILED";

export const START_WORKTIME = "START_WORKTIME";
export const START_WORKTIME_SUCCESS = "START_WORKTIME_SUCCESS";
export const START_WORKTIME_FAILED = "START_WORKTIME_FAILED";

export const END_WORKTIME = "END_WORKTIME";
export const END_WORKTIME_SUCCESS = "END_WORKTIME_SUCCESS";
export const END_WORKTIME_FAILED = "END_WORKTIME_FAILED";

export const ADD_WORKTIME = "ADD_WORKTIME";
export const ADD_WORKTIME_SUCCESS = "ADD_WORKTIME_SUCCESS";
export const ADD_WORKTIME_FAILED = "ADD_WORKTIME_FAILED";

export const CREATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS =
  "CREATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS";
export const CREATE_TICKET_GROUPS_TICKET_TYPE_FAILED =
  "BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED";
export const CREATE_TICKET_GROUPS_TICKET_TYPE =
  "CREATE_TICKET_GROUPS_TICKET_TYPE";

export const UPDATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS =
  "UPDATE_TICKET_GROUPS_LEADER_SUCCESS";
export const UPDATE_TICKET_GROUPS_TICKET_TYPE_FAILED =
  "UPDATE_TICKET_GROUPS_LEADER_FAILED";
export const UPDATE_TICKET_GROUPS_TICKET_TYPE =
  "UPDATE_TICKET_GROUPS_LEADER_REQUEST";

export const DELETE_TICKET_GROUPS_TICKET_TYPE_SUCCESS =
  "DELETE_TICKET_GROUPS_TICKET_TYPE_SUCCESS";
export const DELETE_TICKET_GROUPS_TICKET_TYPE_FAILED =
  "DELETE_TICKET_GROUPS_TICKET_TYPE_FAILED";
export const DELETE_TICKET_GROUPS_TICKET_TYPE =
  "DELETE_TICKET_GROUPS_TICKET_TYPE";

export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILED = "CREATE_COMMENT_FAILED";

export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILED = "FETCH_COMMENTS_FAILED";
export const SET_COMMENTS = "SET_COMMENTS";

export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_FAILED = "FETCH_TAGS_FAILED";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_SUCCESS_FAILED";

export const READ_TICKET_NOTIFICATIONS = "READ_TICKET_NOTIFICATIONS";

export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const SET_ARCHIVED_COUNT = "SET_ARCHIVED_COUNT";
export const SET_COMPANY_REF = "SET_COMPANY_REF";
export const SET_COMPANIES = "SET_COMPANIES";

export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILED = "FETCH_MESSAGES_FAILED";

export const FETCH_LINK_MESSAGES = "FETCH_LINK_MESSAGES";
export const FETCH_LINK_MESSAGES_SUCCESS = "FETCH_LINK_MESSAGES_SUCCESS";
export const FETCH_LINK_MESSAGES_FAILED = "FETCH_LINK_MESSAGES_FAILED";

export const FETCH_ARCHIVE_MESSAGES = "FETCH_ARCHIVE_MESSAGES";
export const FETCH_ARCHIVE_MESSAGES_SUCCESS = "FETCH_ARCHIVE_MESSAGES_SUCCESS";
export const FETCH_ARCHIVE_MESSAGES_FAILED = "FETCH_ARCHIVE_MESSAGES_FAILED";

export const FETCH_CHAT_ATTACHMENTS = "FETCH_CHAT_ATTACHMENTS";
export const SET_CHAT_ATTACHMENTS = "SET_CHAT_ATTACHMENTS";
export const FETCH_CHAT_ATTACHMENTS_SUCCESS = "FETCH_CHAT_ATTACHMENTS_SUCCESS";
export const FETCH_CHAT_ATTACHMENTS_FAILED = "FETCH_CHAT_ATTACHMENTS_FAILED";

export const FETCH_NEW_MESSAGES = "FETCH_NEW_MESSAGES";
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";
export const FETCH_NEW_MESSAGES_SUCCESS = "FETCH_NEW_MESSAGES_SUCCESS";
export const FETCH_NEW_MESSAGES_FAILED = "FETCH_NEW_MESSAGES_FAILED";

export const FETCH_NEW_MESSAGES_COUNT = "FETCH_NEW_MESSAGES_COUNT";
export const FETCH_NEW_MESSAGES_COUNT_SUCCESS =
  "FETCH_NEW_MESSAGES_COUNT_SUCCESS";
export const FETCH_NEW_MESSAGES_COUNT_FAILED =
  "FETCH_NEW_MESSAGES_COUNT_FAILED";

export const FETCH_CHAT_USERS = "FETCH_CHAT_USERS";
export const FETCH_CHAT_USERS_SUCCESS = "FETCH_CHAT_USERS_SUCCESS";
export const FETCH_CHAT_USERS_FAILED = "FETCH_CHAT_USERS_FAILED";

export const FETCH_CHAT_ROOMS = "FETCH_CHAT_ROOMS";
export const FETCH_CHAT_ROOMS_SUCCESS = "FETCH_CHAT_ROOMS_SUCCESS";
export const FETCH_CHAT_ROOMS_FAILED = "FETCH_CHAT_ROOMS_FAILED";

export const FETCH_CHAT_USER_TAGS = "FETCH_CHAT_USER_TAGS";
export const FETCH_CHAT_USER_TAGS_SUCCESS = "FETCH_CHAT_USER_TAGS_SUCCESS";
export const FETCH_CHAT_USER_TAGS_FAILED = "FETCH_CHAT_USER_TAGS_FAILED";

export const FETCH_CHAT_TICKETS = "FETCH_CHAT_TICKETS";
export const FETCH_CHAT_TICKETS_SUCCESS = "FETCH_CHAT_TICKETS_SUCCESS";
export const FETCH_CHAT_TICKETS_FAILED = "FETCH_CHAT_TICKETS_FAILED";

export const POST_CHAT_MESSAGE = "POST_CHAT_MESSAGE";
export const POST_CHAT_MESSAGE_SUCCESS = "POST_CHAT_MESSAGE_SUCCESS";
export const POST_CHAT_MESSAGE_FAILED = "POST_CHAT_MESSAGE_FAILED";

export const POST_CHAT_REACTION = "POST_CHAT_REACTION";
export const POST_CHAT_REACTION_SUCCESS = "POST_CHAT_REACTION_SUCCESS";
export const POST_CHAT_REACTION_FAILED = "POST_CHAT_REACTION_FAILED";

export const SET_NEW_MESSAGES = "SET_NEW_MESSAGES";
export const SET_SELECTED_CHAT_ROOM = "SET_SELECTED_CHAT_ROOM";

export const FETCH_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS =
  "FETCH_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS";
export const FETCH_USER_TICKET_GROUP_MEMBERSHIP_FAILED =
  "FETCH_USER_TICKET_GROUP_MEMBERSHIP_FAILED";
export const FETCH_USER_TICKET_GROUP_MEMBERSHIP =
  "FETCH_USER_TICKET_GROUP_MEMBERSHIP";

export const BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP =
  "BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP";
export const BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS =
  "BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS";
export const BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_FAILED =
  "BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_FAILED";

export const FETCH_LAST_VISITED = "FETCH_LAST_VISITED";
export const FETCH_LAST_VISITED_SUCCESS = "FETCH_LAST_VISITED_SUCCESS";
export const FETCH_LAST_VISITED_FAILED = "FETCH_LAST_VISITED_FAILED";


export const FETCH_LOCALS_REQUEST = "FETCH_LOCALS_REQUEST";
export const SET_LOCALS = "SET_LOCALS";
export const FETCH_LOCALS_FAILED = "FETCH_LOCALS_FAILED";
export const CREATE_LOCAL_REQUEST = "CREATE_LOCAL_REQUEST";
export const CREATE_LOCAL_REQUEST_SUCCESS = "CREATE_LOCAL_REQUEST_SUCCESS";
export const CREATE_LOCAL_REQUEST_FAILED = "CREATE_LOCAL_REQUEST_FAILED";
export const REMOVE_LOCAL_REQUEST = "REMOVE_LOCAL_REQUEST";
export const REMOVE_LOCAL_REQUEST_SUCCESS = "REMOVE_LOCAL_REQUEST_SUCCESS";
export const REMOVE_LOCAL_REQUEST_FAILED = "REMOVE_LOCAL_REQUEST_FAILED";
export const FETCH_LOCAL_REQUEST = "FETCH_LOCAL_REQUEST";
export const SET_LOCAL = "SET_LOCAL";
export const FETCH_LOCAL_REQUEST_SUCCESS = "FETCH_LOCAL_REQUEST_SUCCESS";
export const FETCH_LOCAL_REQUEST_FAILED = "FETCH_LOCAL_REQUEST_FAILED";
export const EDIT_LOCAL_REQUEST = "EDIT_LOCAL_REQUEST";
export const EDIT_LOCAL_REQUEST_SUCCESS = "EDIT_LOCAL_REQUEST_SUCCESS";
export const EDIT_LOCAL_REQUEST_FAILED = "EDIT_LOCAL_REQUEST_FAILED";
